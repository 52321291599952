export const languageOptions = {
    'Personal Absence Report' : 'PAR.Header.AppName',
    'Go': 'PAR.Header.ButtonGo',
    'Chinese (Hong Kong)' : 'PAR.Header.LanguageDropdown.ChineseHongkong',
    'Chinese (Simplified)' : 'PAR.Header.LanguageDropdown.ChineseSimplified',
    'Chinese (Traditional)' : 'PAR.Header.LanguageDropdown.ChineseTraditional',
    'Dutch' : 'PAR.Header.LanguageDropdown.Dutch',
    'French (France)' : 'PAR.Header.LanguageDropdown.FrenchFrance',
    'German (Germany)' : 'PAR.Header.LanguageDropdown.GermanGermany',
    'Italian (Italy)' : 'PAR.Header.LanguageDropdown.ItalianItaly',
    'Japanese' : 'PAR.Header.LanguageDropdown.Japanese',
    'Korean' : 'PAR.Header.LanguageDropdown.Korean',
    'Portuguese (Brazil)' : 'PAR.Header.LanguageDropdown.PortugueseBrazil',
    'Spanish (Mexico)' : 'PAR.Header.LanguageDropdown.SpanishMexico',
    'Spanish (Spain)' : 'PAR.Header.LanguageDropdown.SpanishSpain',
    'Swedish' : 'PAR.Header.LanguageDropdown.Swedish',
    'Thai' : 'PAR.Header.LanguageDropdown.Thai',
    'Turkish' : 'PAR.Header.LanguageDropdown.Turkish',
    'Search geo' : 'PAR.Header.Filter.SearchGeo',
    'Geo' : 'PAR.Header.Filter.Geo',
    'Country' : 'PAR.Header.Filter.Country',
    'Market Team' : 'PAR.Header.Filter.MarketTeam',
    'Market' : 'PAR.Header.Filter.Market',
    'Store' : 'PAR.Header.Filter.Store',
    'Time Period' : 'PAR.Header.Filter.TimePeriod',
    'Absence Type' : 'PAR.Header.Filter.AbsenceType',
    'Prior' : 'PAR.Header.Filter.TimePeriod.Prior',
    'days' : 'PAR.Header.Filter.TimePeriod.Days',
    'Custom' : 'PAR.Header.Filter.TimePeriod.Custom',
    'Jan' : 'PAR.Header.Filter.TimePeriod.Label.Jan',
    'Feb' : 'PAR.Header.Filter.TimePeriod.Label.Feb',
    'Mar' : 'PAR.Header.Filter.TimePeriod.Label.Mar',
    'Apr' : 'PAR.Header.Filter.TimePeriod.Label.Apr',
    'May' : 'PAR.Header.Filter.TimePeriod.Label.May',
    'Jun' : 'PAR.Header.Filter.TimePeriod.Label.Jun',
    'Jul' : 'PAR.Header.Filter.TimePeriod.Label.Jul',
    'Aug' : 'PAR.Header.Filter.TimePeriod.Label.Aug',
    'Sep' : 'PAR.Header.Filter.TimePeriod.Label.Sep',
    'Oct' : 'PAR.Header.Filter.TimePeriod.Label.Oct',
    'Nov' : 'PAR.Header.Filter.TimePeriod.Label.Nov',
    'Dec' : 'PAR.Header.Filter.TimePeriod.Label.Dec',
    'to' : 'PAR.Header.Filter.TimePeriod.Label.To',
    'All' : 'PAR.Header.Filter.AbsenceType.All',
    'Unexcused only' : 'PAR.Header.Filter.AbsenceType.UnexcusedOnly',
    'Excused only' : 'PAR.Header.Filter.AbsenceType.ExcusedOnly',
    'Apply' : 'PAR.Header.Filter.Button.Apply',
    'Cancel' : 'PAR.Header.Filter.Button.Cancel',
    'Getting Started Guide' : 'PAR.Header.InformationIcon.HoverTooltip',
    'Summary' : 'PAR.SummarySection.Summary',
    'Hours' : 'PAR.SummarySection.Hours',
    'Impacted Hours' : 'PAR.SummarySection.Hours.ImpactedHours',
    'Planned Hours' : 'PAR.SummarySection.Hours.PlannedHours',
    '% Impact to Planned Hours' : 'PAR.SummarySection.Hours.PercentImpactToPlannedHours',
    'Shifts' : 'PAR.SummarySection.Shifts',
    'Team member' : 'PAR.SummarySection.TeamMember',
    'Search team member' : 'PAR.SummarySection.TeamMember.DropdownList.SearchTeamMember',
    'Cancel' : 'PAR.SummarySection.TeamMember.DropdownList.Cancel',
    'Apply' : 'PAR.SummarySection.TeamMember.DropdownList.Apply',
    'Impacted Shifts' : 'PAR.SummarySection.Hours.ImpactedShifts',
    'Planned Shifts' : 'PAR.SummarySection.Hours.PlannedShifts',
    '% Impact to Planned Shifts' : 'PAR.SummarySection.Hours.PercentImpactToPlannedShifts',
    'Impact by Excusal Type' : 'PAR.SummarySection.ImpactByExcusalType',
    '% Impact to Planned Hours' : 'PAR.SummarySection.ImpactByExcusalType.PercentImpactToPlannedHours',
    'Unexcused' : 'PAR.SummarySection.ImpactByExcusalType.PercentImpactToPlannedHours.Unexcused',
    'Excused' : 'PAR.SummarySection.ImpactByExcusalType.PercentImpactToPlannedHours.Excused',
    '% Impact to Planned Shifts' : 'PAR.SummarySection.ImpactByExcusalType.PercentImpactToPlannedShifts',
    'Unexcused' : 'PAR.SummarySection.ImpactByExcusalType.PercentImpactToPlannedShifts.Unexcused',
    'Excused' : 'PAR.SummarySection.ImpactByExcusalType.PercentImpactToPlannedShifts.Excused',
    'Occurrences' : 'PAR.SummarySection.Occurrences',
    'Balance as of Today' : 'PAR.SummarySection.Occurences.BalanceAsOfToday',
    'Calender View' : 'PAR.CalenderView',
    'Out of Scope' : 'PAR.CalenderView.OutOfScope',
    'Excused' : 'PAR.CalenderView.Excused',
    'Unexcused' : 'PAR.CalenderView.Unexcused',
    'No Event' : 'PAR.CalenderView.NoEvent',
    'Failure to Notify' : 'PAR.CalenderView.FailureToNotify',
    'Callout' : 'PAR.CalenderView.CallOut',
    'No Punch' : 'PAR.CalenderView.NoPunch',
    'Tardy' : 'PAR.CalenderView.Tardy',
    'Late from Lunch' : 'PAR.CalenderView.LateFromLunch',
    'Early Out' : 'PAR.CalenderView.EarlyOut',
    'Shift' : 'PAR.CalenderView.Tooltip.ShiftDuration',
    'Impact' : 'PAR.CalenderView.Tooltip.TotalTimeImpacted',
    'Time Away' : 'PAR.CalenderView.Tooltip.TimeAway',
    'Leave' : 'PAR.CalenderView.Tooltip.Leave',
    'By Leader': 'PAR.CalenderView.Tooltip.ByLeader'
}