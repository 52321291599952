import { createSlice } from "@reduxjs/toolkit";
import fetchApi from '../service/Service';

const initialState = {
    isSigninSuccess: false
};

export const signin = fetchApi('signin', 'POST')

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: (builder) => { 
      builder.addCase(signin.pending, (state) => {
          
      });

      builder.addCase(signin.fulfilled, (state, action) => {
          state.isSigninSuccess = action.payload && action.payload.status === 'success' ? true : false
      });

      builder.addCase(signin.rejected, (state, action) => {
          const { error } = action;
          state.error = error;
      });
  }
});

export default authenticationSlice.reducer;