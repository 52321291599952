import { configureStore } from '@reduxjs/toolkit'
import headerReducer from'../slices/headerSlice' 
import summuryReducer from '../slices/summarySlice'
import authenticationReducer from '../slices/authenticationSlice'
import CalenderReducer from '../slices/calenderSlice'

export const store = configureStore({
  reducer: {
    header: headerReducer,
    summary:summuryReducer,
    calender:CalenderReducer,
    authenticationData: authenticationReducer
  },
})