import { createSlice } from '@reduxjs/toolkit';
import fetchApi from '../service/Service';

const initialState = {
  isPopupClicked: false,
  data:[],
  timeframeData:[],
  error:null,
  language:{},
  timePeriod:'90',
  excusalStatus:'All',
  attendanceEvent:['All'],
  attendanceEventAll:["Failure To Notify","Callout","No Punch","Tardy","Leave from Lunch","Early Out"],
  customStartDate:'',
  customEndDate:'',
  loading:false,
  cookieData:{},
  selectedLanguage:"English",
  corporateEmployee:false,
  securityRefIds:[],
  inAppNotificationData:[],
  corporateTimeframeData:[],
  store:"",
  corporateStoreList:[],
  teamMemberList:[],
  selectedMember:"",
  isMobile:"N",
  reset:{
    store:'',
    timePeriod:'90',
    excusalStatus:'All',
    attendanceEvent:['All'],
  },
}

export const fetchTimeframeData = fetchApi('fetchTimeframeData', 'POST');
export const fetchData = fetchApi('fetchData', 'POST');
export const fetchDsGroupIds = fetchApi('fetchDsGroupIds', 'POST');
export const fetchSecurityRefDsGroupIds = fetchApi('fetchSecurityRefDsGroupIds', 'POST');
export const fetchInAppNotificationData = fetchApi('fetchInAppNotificationData', 'POST');
export const fetchCorporateStoreList = fetchApi('fetchCorporateStoreList', 'POST');
export const fetchEmployeeDetails = fetchApi('fetchEmployeeDetails', 'POST');
export const fetchCorporateTimeframeData = fetchApi('fetchCorporateTimeframeData', 'POST');

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setPopupState: (state, action) => {
      state.isPopupClicked = action.payload
    },
    setApplyClick: (state, action) => {
      state.isApplyClicked = action.payload
    },
    setTabstate:(state,action)=>{
      state.tab = action.payload
    },
    setTimePeriodState:(state,action)=>{
      state.timePeriod = action.payload
    },
    setAbsenceTypeState:(state,action)=>{
      state.absenceType = action.payload
    },
    setFilterData:(state,action)=>{
      state[action.payload.state] = action.payload.value //dispatch(setFilterData({ state: "reset", value: obj }));
    }
  
  },
  extraReducers: (builder) => {
    /**
     * fetchData logic here.
     */
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.result
      state.loading = false
      state.isPopupClicked = false
    })

    builder.addCase(fetchTimeframeData.fulfilled, (state, action) => {
      state.timeframeData = action.payload.result
      state.isPopupClicked = false
    })
    builder.addCase(fetchDsGroupIds.fulfilled, (state, action) => {
      state.cookieData = action.payload.result;
    })

    builder.addCase(fetchSecurityRefDsGroupIds.fulfilled, (state, action) => {
      state.securityRefIds = action.payload.result
    })
    builder.addCase(fetchInAppNotificationData.fulfilled, (state, action) => {
      state.inAppNotificationData = action.payload.result
    })
    builder.addCase(fetchCorporateStoreList.fulfilled, (state, action) => {
      state.corporateStoreList = action.payload.result
    })

    builder.addCase(fetchEmployeeDetails.fulfilled, (state, action) => {
      state.teamMemberList = action.payload.result
    })
    builder.addCase(fetchCorporateTimeframeData.fulfilled, (state, action) => {
      state.timeframeData = action.payload.result
    })
    builder.addCase(fetchData.rejected, (state, action) => {
      const { error } = action
      state.error = error
      state.loading = false
    })
},
})

export const { setPopupState,setApplyClick,setTabstate,setTimePeriodState,setAbsenceTypeState,setFilterData } = headerSlice.actions

export default headerSlice.reducer