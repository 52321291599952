import React, { useState, useEffect } from 'react';
import { BricksLabel, BricksTextField, BricksRadioButton,BricksButton } from '@nexus/bricks-react';
import { useSelector, useDispatch } from 'react-redux';
import './Dropdown.scss';

const Dropdown = (props) => {

    const header = useSelector((state) => state.header),
        tempSelected = props.selected,
        [selected, setSelected] = useState(props.selected),
        [openPopup, setOpenPopup] = useState(false),
        [searchTerm, setSearchTerm] = useState('');


        useEffect(() => {
            setSelected(props.selected);
          }, [props.selected]); 
    const handleClickApply = () => {
        setSearchTerm('');
        props.dispatchSelection(selected);
        setOpenPopup(false);
    };
    const handleClickCancel = () => {
        setSelected(tempSelected);
        setOpenPopup(false);
    };

    const filteredData = props.data.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const truncateString = (str) => {
        return str.length > 15 ? str.slice(0, 15) + '...' : str;
    }

    const handleChange = (value) => {
        setSelected(value);
    };
    return (
        <div className="dropdown">
            <div className="custom-select" onClick={() => {
                setOpenPopup(!openPopup);
            }}>
                <label>{props.label}</label>
                <div className='multi-select-div-wrapper'>
                    <div className={(openPopup) ? 'select-selected-active' : 'multi-select-selected '}>{(selected !== '') ? truncateString(selected) : props.selected}<span className={(openPopup) ? 'multi-select-selected-collapse-arrow' : 'multi-select-selected-expand-arrow'}></span></div>

                </div>
            </div>
            {
                openPopup
                    ?
                    <div className="popup-overlay" onClick={() => {
                        setSelected(tempSelected);
                        setOpenPopup(false);
                    }}>

                    </div>
                    :
                    null
            }
            {
                openPopup
                    ?
                    <div className="dropdown-popup">

                        <div className='store-content'>
                            <div class="search-box">
                                <BricksTextField label="Standard" className='custom-text-field' onBricksTextFieldChange={e => setSearchTerm(e.detail.value)} showClearButton={true} showSearchIcon={true} placeHolderText={'Search Member'} ></BricksTextField>
                            </div>
                            <div class="search-result">
                                {filteredData.length > 0 ? (
                                    filteredData.map((item) => (
                                        <div className='custom-radio'>
                                            <BricksRadioButton
                                                key={item}
                                                label={item}
                                                value={item}
                                                name="teams"
                                                helper-text={props.formattedTeams[item].Job_Title_Desc + ' - ' + props.formattedTeams[item].JOB_TYPE_CD}
                                                checked={selected == item}
                                                onBricksRadioButtonChange={() => handleChange(item)}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>No Members Found</p>
                                )}
                            </div>
                        </div>

                        <div className="dropdown-footer">
                            <div><BricksButton visualStyle="secondary" accessibleTitle="Secondary" onBricksClick={handleClickCancel}>Cancel</BricksButton></div>
                            <div><BricksButton visualStyle="primary" accessibleTitle="Primary" onBricksClick={handleClickApply}>Apply</BricksButton></div></div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default Dropdown;