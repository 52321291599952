import React, {useEffect} from 'react';
import './InAppSwitch.scss';
import QuasiModalHeader from './QuasiModalHeader';

const InAppSwitch = (props) => {
	
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const showNotifications = (event) => {
        props.setShowAppNotificationPopup(true);
	}
    
    const getNotificationCount = () => {
        let appNotificationData = props.appNotificationData,
            div;

        if(appNotificationData && appNotificationData.length > 0) {
            div = <span className="notification-count-div">{appNotificationData.length}</span>;
        }

        return div;
    }
    const getNotificationPopup = () => {
        let notificationPopup = <QuasiModalHeader  showAppNotificationPopup={props.showAppNotificationPopup} setShowAppNotificationPopup={props.setShowAppNotificationPopup} appNotificationData={props.appNotificationData} />;
        return notificationPopup;
    }
    return (
        <div>
            <div className={(isMobile) ? 'inApp-mobile' : 'inApp'} onClick={showNotifications}>
                {getNotificationCount()}
                <span className={(isMobile) ? 'notification-icon' : 'in-app-img notificationImg'} title="In App"></span>
            </div>
            {props.showAppNotificationPopup? getNotificationPopup(): null}
        </div>
    )
}

export default InAppSwitch