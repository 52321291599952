import * as constants from '../constants/Constant';

export function formatgeo(data, rpoData, result) {
    if (data && data.length > 0) {
        constants.orgKeys.forEach(orgkey => {
            result['ALL'][orgkey] = [];
        });

        data.forEach(obj => {
            if (constants.orgKeys.indexOf(obj["Org_Level_Desc"]) !== -1) {
                result['ALL'][obj["Org_Level_Desc"]].push(obj);
            }
        });

        result['ALL']['Region'].unshift({
            "Page_Name": "TREND",
            "Org_Cd": "WW",
            "Org_Name": "WW",
            "Org_Level_Desc": "Region",
            "Parent_Org_Cd": "",
            "Sched_Day_Name": "",
            "Edit_Ranking_Metric_Ind": "?"
        });
    }
    
    

    if (rpoData && rpoData.length > 0) {
        constants.orgKeysRpo.forEach(orgkey => {
            result['RPO'][orgkey] = [];
        });

        rpoData.forEach(obj => {
            if (constants.orgKeysRpo.indexOf(obj["Org_Level_Desc"]) !== -1) {
                result['RPO'][obj["Org_Level_Desc"]].push(obj);
            }
        });

        result['RPO']['RPO Region'].unshift({
            "Page_Name": "RPO",
            "Org_Cd": "WW",
            "Org_Name": "WW",
            "Org_Level_Desc": "RPO Region",
            "Parent_Org_Cd": "",
            "Sched_Day_Name": "",
            "Edit_Ranking_Metric_Ind": "?"
        });
    }

    //result['ALL'].Store.splice(1);
    //result['RPO']['RPO Store'].splice(1);

    return result;
}
function formatweekresponse(data) {
    let result = [];

    try {
        data.forEach(obj => {
            if (obj.fiscal_child_desc && obj.fiscal_child_desc !== "Total") {
                result.push({
                    "Year": obj.fiscal_child_desc.split('Q')[0],
                    "Quarter": 'Q' + obj.fiscal_child_desc.split('Q')[1].split('W')[0],
                    "Week": obj.fiscal_child_cd.split('-')[1],
                    "Start_date": obj.MIN,
                    "End_date": obj.MAX,
                    "dbfield": obj.fiscal_child_cd
                })
            }
        })
    } catch {
        console.error("custom week data format is not correct");
    }

    return result;
}
export function formatCustomWeek(data) {
    data = formatweekresponse(data);

    let result = {},
        weekdataarray = [];

    data.forEach(obj => {
        weekdataarray.push(obj["dbfield"]);

        if (obj["Year"] in result) {
            if (obj["Quarter"] in result[obj["Year"]]) {
                result[obj["Year"]][obj["Quarter"]].set(obj["Week"], {
                    "start_date": obj["Start_date"],
                    "end_date": obj["End_date"],
                    "dbfield": obj["dbfield"]
                });
            } else {
                result[obj["Year"]][obj["Quarter"]] = new Map();
                result[obj["Year"]][obj["Quarter"]].set(obj["Week"], {
                    "start_date": obj["Start_date"],
                    "end_date": obj["End_date"],
                    "dbfield": obj["dbfield"]
                });
            }
        } else {
            result[obj["Year"]] = {};
            result[obj["Year"]][obj["Quarter"]] = new Map();
            result[obj["Year"]][obj["Quarter"]].set(obj["Week"], {
                "start_date": obj["Start_date"],
                "end_date": obj["End_date"],
                "dbfield": obj["dbfield"]
            });
        }
    });

    return { result, weekdataarray };
}
export function formatTimeframe(data) {
    return data;
}
export function getSelectedReportingDate(reportingDateRes) {
    let selectedReportingDate;

    if (reportingDateRes && reportingDateRes.length > 0) {
        reportingDateRes.sort(function (a, b) {
            let aa = a['Reporting_Dt'].split('-').join(),
                bb = b['Reporting_Dt'].split('-').join();

            return (
                (aa < bb ? -1 : (aa > bb ? 1 : 0))
            )
        });

        selectedReportingDate = reportingDateRes[0]
    }

    return selectedReportingDate;
}
export function formatAvailabilitySummaryTimeframeResponse(data, reportingDateRes) {
    let key = "Timeframe_Cd",
        selectedData = data,
        selectedDataReduced = selectedData.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
        }, {});

    return selectedDataReduced;
}
export function getSelectedTimeframe(data, reportingDateRes) {
    let key = "Timeframe_Cd",
        selectedData = data,
        selectedDataReduced = selectedData.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
        }, {});

    return selectedDataReduced;
}
export function getAvailabilitySummaryInitialParam(dataObj) {
    const month = { "01": "January", "02": "February", "03": "March", "04": "April", "05": "May", "06": "June", "07": "July", "08": "August", "09": "September", "10": "October", "11": "November", "12": "December" };
    let startDay = dataObj['Sat_Sched_Week_Start_Day'],
        startDayYear, startDayMonth, startDayDate,
        endDay = dataObj['Mon_Sched_Week_End_Day'],
        endDayYear, endDayMonth, endDayDate,
        dateLabel;

    if (startDay) {
        let startDaySplitted = startDay.split('-');
        startDayYear = startDaySplitted[0];
        startDayMonth = startDaySplitted[1];
        startDayDate = startDaySplitted[2];
    }
    if (endDay) {
        let endDaySplitted = endDay.split('-');
        endDayYear = endDaySplitted[0];
        endDayMonth = endDaySplitted[1];
        endDayDate = endDaySplitted[2];
    }

    if (startDayMonth === endDayMonth && startDayYear === endDayYear) {
        dateLabel = `${month[startDayMonth]} ${startDayDate}-${endDayDate}, ${startDayYear}`
    } else {
        dateLabel = `${month[startDayMonth]} ${startDayDate} ${startDayYear} - ${month[endDayMonth]} ${endDayDate} ${endDayYear}`
    }

    let scheduledWeekCode = dataObj.Sched_Week_cd,
        weekCode = scheduledWeekCode.replace(' ', "").replace('Wk', "");

    return {
        selectedTimeframe: dateLabel,
        selectedWeek: weekCode,
        timeframeType: 'Current Week',
        FyQuarter: dataObj.Sched_Quarter_cd,
        Order_Nr: dataObj.Order_Nr,
        Fiscal_Order_Cd: dataObj.Fiscal_Order_Cd
    }
}
export function getTeamAvailabilityWeekLabelConfig(dataObj, scheduledDayKey, weekCode) {
    const month = { "01": "January", "02": "February", "03": "March", "04": "April", "05": "May", "06": "June", "07": "July", "08": "August", "09": "September", "10": "October", "11": "November", "12": "December" };

    let startDay = dataObj[scheduledDayKey[0]],
        startDayYear, startDayMonth, startDayDate,
        endDay = dataObj[scheduledDayKey[1]],
        endDayYear, endDayMonth, endDayDate,
        dateLabel;

    if (startDay) {
        let startDaySplitted = startDay.split('-');
        startDayYear = startDaySplitted[0];
        startDayMonth = startDaySplitted[1];
        startDayDate = startDaySplitted[2];
    }
    if (endDay) {
        let endDaySplitted = endDay.split('-');
        endDayYear = endDaySplitted[0];
        endDayMonth = endDaySplitted[1];
        endDayDate = endDaySplitted[2];
    }

    if (startDayMonth === endDayMonth && startDayYear === endDayYear) {
        dateLabel = `${month[startDayMonth]} ${startDayDate}-${endDayDate}, ${startDayYear}`
    } else {
        dateLabel = `${month[startDayMonth]} ${startDayDate} ${startDayYear} - ${month[endDayMonth]} ${endDayDate} ${endDayYear}`
    }

    return {
        weekLabel: weekCode,
        dateLabel: dateLabel
    }
}
export function convertTime(timeStr) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    let decimalMinute = minutes;
    if (minutes !== '00') {
        let decimalMinuteTemp = (10 / 60) * minutes;
        decimalMinute = `${decimalMinuteTemp}`.replace('.', "")
    }

    return {
        time: time,
        modifier: modifier,
        hours: hours,
        minutes: minutes,
        timeIn24Hr: Number(`${hours}.${decimalMinute}`)
    };
}
export function getTimeDiff(startTime, endTime) {
    let startTimeConvertConfig = convertTime(startTime),
        endTimeConvertConfig = convertTime(endTime),
        startTimeIn24Hr = startTimeConvertConfig.timeIn24Hr,
        endTimeIn24Hr = endTimeConvertConfig.timeIn24Hr;

    if (endTimeConvertConfig.modifier === 'AM' && `${endTimeConvertConfig.timeIn24Hr}` === '0') {
        endTimeIn24Hr = 24;
    }

    return {
        startTimeIn24Hr: startTimeIn24Hr,
        endTimeIn24Hr: endTimeIn24Hr,
        timeDiff: Math.round((parseFloat(endTimeIn24Hr) - parseFloat(startTimeIn24Hr)) * 100) / 100
    }
}
export function getfinalstoreselection(filterData) {
    let cd_list,
        resultset,
        geoOrder,
        orgHierSelectionKey;

    if (filterData.selectedChannel.toUpperCase() === 'RPO') {
        resultset = [];
        geoOrder = ["RPO Store", "RPO Pod", "RPO Team", "RPO Region"];
        orgHierSelectionKey = 'RPO';
    } else {
        resultset = [];
        geoOrder = ["Store", "Market", "Market Team", "Country", "Region"];
        orgHierSelectionKey = 'ALL';
    }

    if (filterData.org_hierarchy && Object.keys(filterData.org_hierarchy).length > 0) {
        for (var key in geoOrder) {
            key = geoOrder[key];
            if (filterData[key].length > 0) {
                var selectedOrgs = filterData[key];
                resultset = filterData.org_hierarchy[orgHierSelectionKey][key].filter(function (obj) {
                    return selectedOrgs.indexOf(obj.Org_Cd) !== -1;
                })
                break;
            }
        }
    }

    let acdOrader = geoOrder.reverse();

    acdOrader = acdOrader.filter((obj, idx) => {
        return idx > acdOrader.indexOf(resultset[0]["Org_Level_Desc"])
    });

    cd_list = resultset.map((obj) => {
        return obj.Org_Cd
    });

    if (cd_list.length === 1 && cd_list[0] === "WW") {
        cd_list = ['AMR', 'EMEIA', 'APAC'];
    }

    if (filterData.org_hierarchy && Object.keys(filterData.org_hierarchy).length > 0) {
        for (var i in acdOrader) {
            let geokey = acdOrader[i];
            cd_list = filterData.org_hierarchy[orgHierSelectionKey][geokey].filter(function (obj) {
                return cd_list.indexOf(obj.Parent_Org_Cd) !== -1;
            }).map((obj) => {
                return obj.Org_Cd
            });
        }
    }

    return cd_list;
}
export function getRegionfromStore(filterData) {
    let resultset,
        geoOrder,
        orgHierSelectionKey, region;

    if (filterData.selectedChannel.toUpperCase() === 'PPO') {
        resultset = [];
        geoOrder = ["RPO Store", "RPO Pod", "RPO Team", "RPO Region"];
        orgHierSelectionKey = 'RPO';
    } else {
        resultset = [];
        geoOrder = ["Store", "Market", "Market Team", "Country", "Region"];
        orgHierSelectionKey = 'ALL';
    }

    if (filterData.org_hierarchy && Object.keys(filterData.org_hierarchy[orgHierSelectionKey]).length > 0) {
        for (var key in geoOrder) {
            key = geoOrder[key];
            if (filterData[key].length > 0) {
                var selectedOrgs = (resultset.length > 0) ? resultset[0].Parent_Org_Cd : filterData[key];

                resultset = filterData.org_hierarchy[orgHierSelectionKey][key].filter(function (obj) {
                    return selectedOrgs.indexOf(obj.Org_Cd) !== -1;
                })
            }
        }
        region = (resultset.length > 0) ? resultset[0].Org_Cd : '';

    }

    return region;
}
export function setDefaultMetrics(metricMapping) {
    let level1metrics = metricMapping.filter((obj) => {
        return obj["level"] === 1;
    }),
        level2metrics_ref = {},
        level2metrics = metricMapping.filter((obj) => {
            level2metrics_ref[obj["DB_Field"]] = obj["Metric_Name"];
            return obj["level"] === 2 && obj["Parent_DB_Field"] === level1metrics[0]["DB_Field"];
        });

    return {
        level1metrics,
        level2metrics,
        level2metrics_ref
    }
}
export function getlevel2selectedinitialMetrics(level2metrics, selectedTF) {
    let result = [],
        tempNA = (selectedTF === "Custom Day" || selectedTF === "CW" || selectedTF === "PW" || selectedTF === "NW") ? true : false,
        templinemetrics = level2metrics.filter(obj => { return obj.unit === "PCT" && !(tempNA && obj.DAILY_DISABLED_IND === "Y") }),
        tempbarmetrics = level2metrics.filter(obj => { return obj.unit !== "PCT" && !(tempNA && obj.DAILY_DISABLED_IND === "Y") });

    result = templinemetrics.slice(0, 2).concat(tempbarmetrics.slice(0, 2));

    if (result.length < 4) {
        result = result.concat(tempbarmetrics.slice(2, (6 - result.length)));
    }
    if (result.length < 4) {
        result = result.concat(templinemetrics.slice(2, (6 - result.length)));
    }
    return result;
}
export function getFormattedRankingMetricMapping(metricMapping) {
    let departmentList = [],
        metricGroupingByDepartment = metricMapping.reduce((r, a) => {
            a["hidden"] = false;
            a["searchVisible"] = true;
            r[a.Department] = [...(r[a.Department] || []), a];
            return r;
        }, {});

    for (let dept of Object.keys(metricGroupingByDepartment)) {
        departmentList.push(dept);
    }

    return {
        metricGroupingByDepartment,
        departmentList
    }
}
export function getFormattedRankingMetricMappingBySession(metricMapping, lastSession) {
    let ranksesflag = false;
    if (lastSession.hasOwnProperty("RANKING_PREF_VAL") && lastSession.hasOwnProperty("RANKING_PREF_VAL") != null) {
        ranksesflag = true;
    }
    let departmentList = [],
        metricGroupingByDepartment = metricMapping.reduce((r, a) => {
            a["hidden"] = ranksesflag ? (lastSession.RANKING_PREF_VAL[a.Department].indexOf(a.Metric_Name) !== -1 ? true : false) : false;
            a["searchVisible"] = true;
            r[a.Department] = [...(r[a.Department] || []), a];
            return r;
        }, {});

    let departmentCheck = {};
    for (let dept of Object.keys(metricGroupingByDepartment)) {
        departmentList.push(dept);
        departmentCheck[dept] = {};
        departmentCheck[dept]["checkType"] = "checked";
        let hiddencount = metricGroupingByDepartment[dept].filter(obj => {
            return obj["hidden"] === true;
        });
        if (hiddencount.length === metricGroupingByDepartment[dept].length) {
            departmentCheck[dept]["checkType"] = "unchecked";
        } else if (hiddencount.length > 0) {
            departmentCheck[dept]["checkType"] = "indeterminate";
        }
    }

    return {
        metricGroupingByDepartment,
        departmentList,
        departmentCheck
    }
}
export function formatLegendInfo(data) {
    var result = [];
    let level1 = [], level2 = [], level3 = [];
    level1 = data.filter(obj => {
        return obj.Parent_Date_Point_Name === "";
    })
    var idx1 = 0;
    var deptname = "";
    let level1keys = level1.map((ele, idx) => {
        if (deptname !== ele.DEPT_NAME) {
            idx1 = 0;
            deptname = ele.DEPT_NAME;
        }
        var idx12 = 0;
        result.push(
            {
                "data": ele,
                "rows": data.reduce((res, obj, idx2) => {

                    if (ele.Data_Point_Name === obj.Parent_Date_Point_Name && ele.DEPT_NAME === obj.DEPT_NAME) {
                        var idx123 = 0;
                        let result2lvl = {
                            "data": obj,
                            "rows": data.reduce((res2, item, idx3) => {
                                if (obj.Data_Point_Name === item.Parent_Date_Point_Name && obj.DEPT_NAME === item.DEPT_NAME) {
                                    let result3lvl = {
                                        "data": item,
                                        "rows": [],
                                        "open": false,
                                        "level": 2,
                                        "index": [idx1, idx12, idx123]
                                    }

                                    res2.push(result3lvl);
                                    idx123++;
                                }
                                return res2;
                            }, []),
                            "open": false,
                            "level": 1,
                            "index": [idx1, idx12]
                        }

                        res.push(result2lvl);
                        idx12++;
                    }

                    return res;
                }, []),
                "open": false,
                "level": 0,
                "index": [idx1]
            }
        )
        idx1++;
        return ele.Data_Point_Name
    });



    level2 = data.filter(obj => {
        return level1keys.indexOf(obj.Parent_Date_Point_Name) > -1;
    })
    let level2keys = level2.map(ele => {
        return ele.Data_Point_Name
    });

    level3 = data.filter(obj => {
        return level2keys.indexOf(obj.Parent_Date_Point_Name) > -1;
    })

    return result;
}
export function getparamforpreference(appId, pageId, filterdata, preference_name, default_ind) {
    let result = {
        "in_app_id": appId,
        "in_page_id": pageId,
        "in_pref_id": null,
        "in_pref_name": preference_name,
        "in_pref_value": JSON.stringify(filterdata),
        "in_default_ind": default_ind ? "Y" : null
    }
    return result;
}
export function getLastSessionFilter(filterData) {
    return {
        selectedTab: filterData.selectedTab,
        Department: filterData.Department,
        Week_Display: filterData.Week_Display,
        Region: filterData.Region,
        Country: filterData.Country,
        "Market Team": filterData["Market Team"],
        Market: filterData.Market,
        Store: filterData.Store,
        SelectedTF: filterData.SelectedTF,
        SelectedWeeks: filterData.SelectedTF === "Custom Week" ? filterData.SelectedWeeks : [],
        start_dt: filterData.SelectedTF === "Custom Day" ? filterData.start_dt : "",
        end_dt: filterData.SelectedTF === "Custom Day" ? filterData.end_dt : "",
        rankBy: filterData.rankBy
    }
}
export function getparamforlastsession(filterdata_ses, trend_chkbox_ses, trend_tab_ses, ranking_ses) {
    if ((filterdata_ses.start_date instanceof Date) && (filterdata_ses.end_dt instanceof Date)) {
        filterdata_ses.start_dt = filterdata_ses.start_dt.getFullYear() + "-" + (filterdata_ses.start_dt.getMonth() + 1) + "-" + filterdata_ses.start_dt.getDate();
        filterdata_ses.end_dt = filterdata_ses.end_dt.getFullYear() + "-" + (filterdata_ses.end_dt.getMonth() + 1) + "-" + filterdata_ses.end_dt.getDate();
    }

    let trend_ses = {
        trenLevel1CheckBox: trend_chkbox_ses,
        trendLevel1Tab: trend_tab_ses
    }

    let ranking_ses_mod = null;

    if (ranking_ses != null) {
        ranking_ses_mod = {};
        for (let key of Object.keys(ranking_ses)) {
            ranking_ses_mod[key] = [];
            for (let obj of ranking_ses[key]) {
                if (obj.hidden) {
                    ranking_ses_mod[key].push(obj.Metric_Name);
                }
            }
        }
    }

    let result = {
        "in_app_id": constants.APPID,
        "in_page_id": constants.PAGEID,
        "in_pref_val": filterdata_ses != null ? JSON.stringify(filterdata_ses) : null,
        "in_trend_pref_val": trend_ses != null ? JSON.stringify(trend_ses) : null,
        "in_ranking_pref_val": (ranking_ses !== null) > 0 ? JSON.stringify(ranking_ses_mod) : null,
        "in_epoch_ts": (new Date()).getTime()
    }

    return result;
}

export function formatNumber(num) {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
}
export function formatTableDate(datestr) {
    let result = datestr;

    if (new Date(datestr) !== "Invalid Date") {
        let datearr = datestr.split("-");
        result = datearr[1] + "/" + datearr[2];
    }
    return result;
}
export function tocamelCase(str) {
    if (str.length > 0) {
        let strarr = str.split(" ");
        strarr = strarr.map(obj => { return obj.substr(0, 1).toUpperCase() + obj.substr(1) })
        str = strarr.join(" ")
    }
    return str;
}

export function getEmeaSchedulerDsGroupId() {
    let EmeaUserDsGroupId = "";

    if (window.location.host === "gbiretail-prz.prz.apple.com" || window.location.host === "gbiretail-msc.msc.apple.com" || window.location.host === "gbiretail.corp.apple.com" 
        || window.location.host === "gbiretail-isc-prz.prz.apple.com" || window.location.host === "gbiretail-isc-msc.msc.apple.com" || window.location.host === "gbiretail-isc.corp.apple.com") {
        EmeaUserDsGroupId = 5724259;
    } else if (window.location.host === "gbiretail-uat.rno.apple.com" || window.location.host === "gbiretail-isc-uat.rno.apple.com") {
        EmeaUserDsGroupId = 20355747;
    }

    return EmeaUserDsGroupId;
}
export function compareArrays(arr1, arr2) {
    return (JSON.stringify(arr1) === JSON.stringify(arr2));
}
export function duplicateObj(obj) {
    var duplicateObj = undefined;
    if (obj.constructor === [].constructor) {
        duplicateObj = [];
        obj.map(d => {
            duplicateObj.push(d);
        });
    } else if (obj.constructor === {}.constructor) {
        duplicateObj = {};
        Object.keys(obj).map(key => {
            duplicateObj[key] = this.duplicateObj(obj[key]);
        });
    } else {
        duplicateObj = obj;
    }
    return duplicateObj;
}
export function groupBy(array, key) {
    return array.reduce((result, obj) => {
        const group = obj[key];
        if (!result[group]) {
            result[group] = [];
        }
        result[group].push(obj);
        return result;
    }, {});
}
export function round(value, precision) {
    return parseFloat(value).toFixed(precision);
    // var multiplier = Math.pow(10, precision || 0);
    // return Math.round(value * multiplier) / multiplier;
}
export function removeDuplicates(originalArray, prop) {
    let newArray = [],
        lookupObject = {};

    for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }

    return newArray;
}
export const getRoundedValue = (num) => {
    let roundedNo;

    if(Number.isInteger(num)) {
      roundedNo = +num;
    } else {
      roundedNo = +(num.toFixed(1));
    }

    return roundedNo;
}
export const getStartDate = (header) => {

    let streamName;
    let startDate;
    if (header.timePeriod == 'Custom') {
        return [header.customStartDate];
    }
    if (header.Region[0] === "WW") {
        if (header.timeframeData.length > 0) {
            streamName = 'AMR';
            startDate = header.timeframeData.find(elem => elem.Rptg_Stream_Name == streamName && elem.Timeframe_Cd == 'CUSTOM_DAY_' + header.timePeriod);

            return [startDate.Start_Dt]
        }
    } else {
        if (header.timeframeData.length > 0) {

            let startDatearr = [];
            for (let region of header.Region) {
                let date1 = header.timeframeData.find(elem => elem.Rptg_Stream_Name == region && elem.Timeframe_Cd == 'CUSTOM_DAY_' + header.timePeriod);
                if(date1)
                startDatearr.push(date1.Start_Dt);

            }
            return [startDatearr.reduce((acc, startDatearr) => { return acc && new Date(acc) < new Date(startDatearr) ? acc : startDatearr }, '')]
        }

    }



    //console.log(state.timePeriod)
}
  export const getEndDate = (header)=>{
    
    let streamName ;
    if(header.timePeriod == 'Custom'){
        return [header.customEndDate];
    }
    if(header.Region[0] === "WW") {
      streamName = 'AMR';
      let endDate = header.timeframeData.find(elem => elem.Rptg_Stream_Name == streamName && elem.Timeframe_Cd == 'CUSTOM_DAY_'+header.timePeriod);
    
    return [endDate.End_Dt]
    }else{
        if(header.timeframeData.length > 0){
      let endDatearr = [];
      for (let region of header.Region) {
        let date = header.timeframeData.find(elem => elem.Rptg_Stream_Name == region && elem.Timeframe_Cd == 'CUSTOM_DAY_'+header.timePeriod);
        if(date)
        endDatearr.push(date.End_Dt);
      }
  
     //console.log(endDatearr,endDatearr.reduce((acc,endDatearr)=>{return acc&&new Date(acc)>new Date(endDatearr)?acc:endDatearr},''),"END ARR")
      return[endDatearr.reduce((acc,endDatearr)=>{return acc&&new Date(acc)>new Date(endDatearr)?acc:endDatearr},'')]
    }
    }
    
  }
  export const getStoreList = (header) =>{
    let cd_list,
          resultset,
          geoOrder,
          orgHierSelectionKey;
  
          resultset = [];
          geoOrder = ["Store", "Market", "Market Team", "Country", "Region"];
          orgHierSelectionKey = 'ALL';
      
      if (header.org_hierarchy && Object.keys(header.org_hierarchy).length > 0) {
          for (var key in geoOrder) {
              key = geoOrder[key];
              if (header[key].length > 0) {
                  var selectedOrgs = header[key];
                  resultset = header.org_hierarchy[orgHierSelectionKey][key].filter(function (obj) {
                      return selectedOrgs.indexOf(obj.Org_Cd) !== -1;
                  })
                  break;
              }
          }
      }
      
  
      let acdOrader = geoOrder.reverse();
  
      acdOrader = acdOrader.filter((obj, idx) => {
          return idx > acdOrader.indexOf(resultset[0]["Org_Level_Desc"])
      });
  
      cd_list = resultset.map((obj) => {
          return obj.Org_Cd
      });

      if (cd_list.length == 1 && cd_list[0] == "WW") {
          cd_list = ['AMR', 'EMEIA', 'APAC'];
      }
  
      if (header.org_hierarchy && Object.keys(header.org_hierarchy).length > 0) {
          for (var i in acdOrader) {

              let geokey = acdOrader[i];
              cd_list = header.org_hierarchy[orgHierSelectionKey][geokey].filter(function (obj) {
                  return cd_list.indexOf(obj.Parent_Org_Cd) !== -1;
              }).map((obj) => {
                  return obj.Org_Cd
              });
          }
      }
  
      return cd_list;
  }
export const getStoreListByItem = (header, item) => {
    item = (item == 'Country/Region') ? 'Country' : item;
    const selection = {
        'Country': ['Market Team', 'Market', 'Store'],
        'Market Team': ['Market', 'Store'],
        'Market': ['Store'],
        'Store': ['Store']
    };

    let cd_list = header[item];
    let acdOrader = selection[item];
    if(item == 'Store'){
        return header[item];
    }else{
    if (header.org_hierarchy && Object.keys(header.org_hierarchy).length > 0) {
        for (var i in acdOrader) {
            let geokey = acdOrader[i];
            cd_list = header.org_hierarchy['ALL'][geokey].filter(function (obj) {
                return cd_list.indexOf(obj.Parent_Org_Cd) !== -1;
            }).map((obj) => {
                return obj.Org_Cd;
            });
            
        }
    }
    return cd_list;
}
}
  export function getDateRange(startDay, endDay) {
    const month = {"01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec"};
        
    let startDayYear, startDayMonth, startDayDate, startDayDateParseInt,
        endDayYear, endDayMonth, endDayDate, endDayDateParseInt,
        dateLabel;

    if(startDay) {
        let startDaySplitted = startDay.split('-');
        startDayYear = startDaySplitted[0];
        startDayMonth = startDaySplitted[1];
        startDayDate = startDaySplitted[2];
        startDayDateParseInt = parseInt(startDayDate, 10);
    }
    if(endDay) {
        let endDaySplitted = endDay.split('-');
        endDayYear = endDaySplitted[0];
        endDayMonth = endDaySplitted[1];
        endDayDate = endDaySplitted[2];
        endDayDateParseInt = parseInt(endDayDate, 10);
    }

    if(startDayMonth === endDayMonth && startDayYear === endDayYear) {
        dateLabel = `${startDayDateParseInt}-${endDayDateParseInt}  ${month[startDayMonth]}`
    } else {
        dateLabel = `${startDayDateParseInt} ${month[startDayMonth]}- ${endDayDateParseInt}  ${month[endDayMonth]}`
    }

    return dateLabel;
}

export const getEvents = (selectedEvents,header) => {
    let eventArray = [];
    if(selectedEvents.length == 0){
        eventArray = ['All'];
    }else if(selectedEvents.length == header.attendanceEventAll.length){
        eventArray = ['All'];
    }else if(selectedEvents.length > 0){
        eventArray = selectedEvents;
    }else {
        eventArray = ['All'];
    }
    return eventArray;
  }
export const getLatestOrgSelection = (header) => {
    //let hierarchy = ['Store','Market','Market Team','Country','Region'];
    let hierarchy = ['Region','Country','Market Team','Market','Store'];
    let orgName = '';
    for (let key of hierarchy) {
        if (header[key] && header[key].length > 0) {
            orgName = (key == 'Region' || key == 'Country') ? 'Country' : key
        }
    }
    return {'org':orgName,'values':header[orgName]}; // or any default value you prefer

}
export const getExcusalTypes=(header)=>{
    let excusalArray = (header.excusalType.length > 0 && header.excusalType != 'All')? header.excusalType : [...new Set(header.dimensionDetails.map(({ Excusal_Type }) => Excusal_Type).filter(value => value !== null && value !== undefined && value !== '' && value != 'NA'))];
    if (!excusalArray.includes('No Event')) {
        excusalArray.push('No Event');
  }
  
  return excusalArray;
  }
export  function formatDateforAudit(dateString) {
   
    const parts = dateString.split('-');
    const year = parts[0].slice(-2);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
        switch (day % 10) {
            case 1:  return 'st';
            case 2:  return 'nd';
            case 3:  return 'rd';
            default: return 'th';
        }
    };

    return `${day}${daySuffix(day)} ${monthNames[month - 1]} ${year}`;
}
export const getTeamEndDate = (header)=>{
    
    let streamName ;
    if(header.teamTimePeriod == 'Custom'){
        return [header.customTeamEndDate];
    }
    if(header.TeamRegion[0] === "WW") {
      streamName = 'AMR';
      let endDate = header.timeframeData.find(elem => elem.Rptg_Stream_Name == streamName && elem.Timeframe_Cd == 'CUSTOM_DAY_'+header.teamTimePeriod);
    
    return [endDate.End_Dt]
    }else{
      let endDatearr = [];
      for (let region of header.TeamRegion) {
        let date = header.timeframeData.find(elem => elem.Rptg_Stream_Name == region && elem.Timeframe_Cd == 'CUSTOM_DAY_'+header.teamTimePeriod);
        endDatearr.push(date.End_Dt);
      }
      return[endDatearr.reduce((acc,endDatearr)=>{return acc&&new Date(acc)>new Date(endDatearr)?acc:endDatearr},'')]
    }
    
  }
  export const getTeamStartDate = (header)=>{
    let streamName ;
    let startDate ;
    if(header.teamTimePeriod == 'Custom'){
        return [header.customTeamStartDate];
    }
    if(header.TeamRegion[0] === "WW") {
      streamName = 'AMR';
      startDate = header.timeframeData.find(elem => elem.Rptg_Stream_Name == streamName && elem.Timeframe_Cd == 'CUSTOM_DAY_'+header.teamTimePeriod);
      return [startDate.Start_Dt]
    }else{
      let startDatearr = [];
      
      for (let region of header.TeamRegion) {
        let date = header.timeframeData.find(elem => elem.Rptg_Stream_Name == region && elem.Timeframe_Cd == 'CUSTOM_DAY_'+header.teamTimePeriod);
        startDatearr.push(date.Start_Dt);
      }
      return[startDatearr.reduce((acc,startDatearr)=>{return acc&&new Date(acc)<new Date(startDatearr)?acc:startDatearr},'')]
    }
    
    
    
    
  }
  export const getEmpBadgeId = (id,list)=> {
    let empBadgeId ;
    const isNumeric = /^\d+$/.test(id);

    if(!isNumeric){
        let details = list.find(emp => emp.Emp_Name == id);
        empBadgeId = details.EMP_DS_ID
    }else{
        empBadgeId = id;
    }

    // console.log(isNumeric,id,list,"LIST")
        return empBadgeId;
  }
  export function getFormattedDate(dateVal) {
    const month = {"01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec"};
        
    let startDayYear, startDayYearLastTwoDgt, startDayMonth, startDayDate, startDayDateParseInt;

    if(dateVal) {
        let startDaySplitted = dateVal.split('-');
        startDayYear = startDaySplitted[0];
        startDayMonth = startDaySplitted[1];
        startDayDate = startDaySplitted[2];
        startDayDateParseInt = parseInt(startDayDate, 10);
        startDayYearLastTwoDgt = startDayYear % 100
    }

    return `${startDayDateParseInt} ${month[startDayMonth]} ${startDayYearLastTwoDgt}`;
 }
 export function getFormattedTime(hour,delmin) {
    const hours = Math.floor(hour);
  
    // Get the fractional part for minutes and convert to minutes
    const minutes = Math.round((hour - hours) * 60);
  
    // Format the minutes to always have two digits
    const formattedMinutes = minutes.toString().padStart(1, '0');
  
    // Return the formatted time
    return minutes > 0 
    ? `${hours}h${delmin}${formattedMinutes}m` 
    : `${hours}h`;
 }
 export const customRound = (number) => {
    const integerPart = Math.floor(number);
    const decimalPart = number - integerPart;

    if (decimalPart < 0.4) {
        return integerPart;
    } else if (decimalPart >= 0.5) {
        return integerPart + 1;
    } else {
        return integerPart;
    }
}
export const  convertToAmPm =(time24)=> {
    // Split the input string by colon
    let [hours, minutes] = time24.split(':').map(Number);

    // Determine the period (AM/PM)
    let period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12 || 12;

    // Return the formatted time
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
}
export const sortObjectKeys = (obj, order) => {
    const sortedObj = {};
    order.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            sortedObj[key] = obj[key];
        }
    });
    return sortedObj;
};
export const formatNumbertoOneDecimal = (number) => {
    let roundedNumber = Math.round(number * 10) / 10;
    
    // Check if the rounded number is an integer
    if (Number.isInteger(roundedNumber)) {
        return roundedNumber.toString(); // Convert to string and return
    } else {
        return roundedNumber.toFixed(1); // Format to one decimal place and return as string
    }
};
export const sortArrayOfObjectsInAlphabeticalOrder = (arr, property) => {
    if(arr ?. length > 0) {
        arr.sort((a, b) => {
            if (a[property] < b[property]) {
                return -1;
            }
            if (a[property] > b[property]) {
                return 1;
            }
            return 0;
        });
    }
    return arr;
};
export const formatDateToDDMMYYYY=(dateString) =>{
    // Split the input date string into year, month, and day
    let [year, month, day] = dateString.split('-').map(Number);

    // Create a new Date object
    let date = new Date(year, month - 1, day); // Month is 0-indexed

    // Get day, month, and year
    let formattedDay = String(date.getDate()).padStart(2, '0');
    let formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
    let formattedYear = date.getFullYear();

    // Return date in dd/mm/yyyy format
    return `${formattedMonth}/${formattedDay}/${formattedYear}`;
}
export const formatNumberToLocalStr =(inputNumber) =>{
    const number = parseFloat(inputNumber); // Convert string to number
  
    if (isNaN(number)) {
      return ''; // Return an empty string for invalid numbers or non-numeric strings
    }
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
  });
}
export  function formatDateforFilter(dateString) {
   
    const parts = dateString.split('-');
    const year = parts[0].slice(-4);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
        switch (day % 10) {
            case 1:  return 'st';
            case 2:  return 'nd';
            case 3:  return 'rd';
            default: return 'th';
        }
    };
    let dateObj = {
        'Day':day+daySuffix(day),
        'Month':monthNames[month - 1],
        'Year':year
    }

    return dateObj;
}