import React from 'react';
import './NoDataDisplayContainer.scss';

const NoDataDisplayContainer = ({msg}) => {
  return (
    <div className='no-date-display-div'>
        <div>{msg}</div>
    </div>
  )
}

export default NoDataDisplayContainer