import React, { useState, useEffect } from 'react';
import './Header.scss';
import { BricksButton, BricksRadioButtonGroup, BricksRadioButton, BricksSelector, BricksMenuItem, BricksDatePicker } from '@nexus/bricks-react';
import { useSelector, useDispatch } from 'react-redux';
import { setTimePeriodState, setApplyClick, setFilterData, fetchEmployeeDetails } from '../../slices/headerSlice';
import AttendanceEventComponent from '../common/AttendanceEventComponent';
import { fetchTimeframeData, fetchCorporateStoreList, fetchCorporateTimeframeData, fetchInAppNotificationData } from '../../slices/headerSlice';
import { fetchSummary, fetchSummaryTotal } from '../../slices/summarySlice';
import { fetchCalenderDetails } from '../../slices/calenderSlice';
import ListView from '../common/ListView';
import * as Util from "../../util/Util";
import * as XLSX from 'xlsx';
import InAppSwitch from '../common/inapp_notification/InAppSwitch';

const Header = () => {
  const header = useSelector((state) => state.header),
    [timePeriod, setTimePeriod] = useState(header.timePeriod),
    [excusalStatus, setExcusalStatus] = useState(header.excusalStatus),
    [attendanceEventsSelected, setAttendanceEventsSelected] = useState(header.attendanceEvent),
    time_periods = ['30', '60', '90', '180', '365', 'Custom'],
    absence_type = ['All', 'Unexcused Only', 'Excused Only'],
    [datePicker, setDatePicker] = useState(''),
    [startDate, setStartDate] = useState(''),
    [endDate, setEndDate] = useState(''),
    [popup, setPopup] = useState(false),
    dispatch = useDispatch(),
    [selectedLanguage, setSelectLanguage] = useState(header.selectedLanguage),
    attendanceEventObject = {
      "Absence": ["Failure To Notify", "Callout", "No Punch"],
      "Time Keeping": ["Tardy", "Leave from Lunch", "Early Out"]
    },
    storeList = [],
    [appNotificationData, setAppNotificationData] = useState(''),
    [showAppNotificationPopup, setShowAppNotificationPopup] = useState(false);

  const handleLanguageChange = (e) => {
    setSelectLanguage(e.detail.value);
  }

  const dispatchAttendanceEventsSelected = (values) => {
    dispatch(setFilterData({ state: "attendanceEvent", value: values }));
    setAttendanceEventsSelected(values);
  }

  useEffect(() => {
    if (header.corporateEmployee){
      dispatch(fetchCorporateTimeframeData());
      dispatch(fetchCorporateStoreList(header))
      setPopup(true)
    }
  }, [header.corporateEmployee]);

  useEffect(() => {
    if (header.inAppNotificationData){
      setAppNotificationData(header.inAppNotificationData)
    }
  }, [header.inAppNotificationData]);

  useEffect(() => {
    setAttendanceEventsSelected(header.attendanceEvent)
  }, [header.attendanceEvent]);

  const formatDate = (date) => {
    const [month, day, year] = date.split("/");
    const inputDate = new Date(year, month - 1, day); // Month is 0-based in Date object
    const formattedDate = `${inputDate.getFullYear()}-${month}-${day}`;
    return formattedDate
  }

  const handleDateChange = (e) => {
    if (e.detail.value) {
      dispatch(setFilterData({ state: "customStartDate", value: formatDate(e.detail.value[0]) }));
      dispatch(setFilterData({ state: "customEndDate", value: formatDate(e.detail.value[1]) }));
      const dateRange = document.getElementById('custom-date');
      dateRange.value = [e.detail.value[0], e.detail.value[1]];
      setDatePicker([e.detail.value[0], e.detail.value[1]])
    }
  }

  useEffect(() => {
    dispatch(setFilterData({ state: "isMobile", value: 'N' }));
    if (!header.corporateEmployee) {
      dispatch(fetchTimeframeData());
    }
    dispatch(fetchInAppNotificationData());
  }, []);

  const onUserGuideIconClick = () => {
    setPopup(false)
    const pdfFilePath = `${process.env.PUBLIC_URL}/multiLingualGuideFiles/Personal_Absence_` + selectedLanguage + `.pdf`;
    // using Java Script method to get PDF file
    fetch(pdfFilePath).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Personal_Absence_" + selectedLanguage + ".pdf";
        alink.click();
      });
    });
  };

  useEffect(() => {
    // Path to the local Excel file in the public folder
    const filePath = `${process.env.PUBLIC_URL}/multiLingual/Personal_Absence_` + selectedLanguage + `.xlsx`;
    fetch(filePath)
      .then((response) => response.arrayBuffer()) // Get the file as an ArrayBuffer
      .then((data) => {
        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const keyValuePairs = jsonData.slice(1).reduce((acc, [key, , source]) => {
          acc[key] = source;
          return acc;
        }, {});
        dispatch(setFilterData({ state: "language", value: keyValuePairs }));
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, [selectedLanguage]);

  useEffect(() => {
    let timeframeData = header.timeframeData;
    if (timeframeData.length > 0) {
      let dateObj = timeframeData.find(entry => entry.Timeframe_Cd == 'CUSTOM_DAY_' + header.timePeriod);
      setStartDate(dateObj.Start_Dt);
      setEndDate(dateObj.End_Dt);
      let params = {
        "StartDate": dateObj.Start_Dt,
        "EndDate": dateObj.End_Dt,
        "ExcusalStatus": excusalStatus,
        "Events": Util.getEvents(attendanceEventsSelected, header),
        "Header": header,
        "CORP_ACCESS": (header.corporateEmployee == true) ? 'Y' : 'N',
      }
      //dispatch(fetchData(params));
      if(!header.corporateEmployee){
        dispatch(fetchSummary(params));
        dispatch(fetchSummaryTotal(params));
        dispatch(fetchCalenderDetails(params));
      }
      
    }
  }, [header.timeframeData])

  const onClickStore = (store) => {
    dispatch(setFilterData({ state: "store", value: store }));
  }

  const handleSubmit = () => {
    dispatch(setTimePeriodState(timePeriod));
    let timeframeData = header.timeframeData;
    if (header.corporateEmployee) {
      if (header.store == "") {
        alert("Please select a store");
        return;
      }
    }
    if (header.corporateEmployee) {
      if (timePeriod !== "Custom") {
        let dateObj = timeframeData.find((entry) =>
            entry.Timeframe_Cd == "CUSTOM_DAY_" +timePeriod &&
            entry.Rptg_Stream_Name == header.store.Rptg_Stream_Name
        );

        let params = {
          StartDate: dateObj.Start_Dt,
          EndDate: dateObj.End_Dt,
          STORE_CD: header.store,
        };
        setStartDate(dateObj.Start_Dt);
        setEndDate(dateObj.End_Dt);
        if (header.store != header.reset.store || header.selectedMember == '')
        {
          dispatch(fetchEmployeeDetails(params));
        }
        else {
          const params = {
            ExcusalStatus: excusalStatus,
            Events: Util.getEvents(attendanceEventsSelected, header),
            Header: header,
            CORP_ACCESS: header.corporateEmployee == true ? "Y" : "N",
            EMP_DS_ID: header.selectedMember.MASKED_EMP_DS_ID,
          };
          if (timePeriod !== "Custom") {
            const dateObj = timeframeData.find(
              (entry) => entry.Timeframe_Cd === "CUSTOM_DAY_" + timePeriod && entry.Rptg_Stream_Name == header.store.Rptg_Stream_Name
            );
            params.StartDate = dateObj.Start_Dt;
            params.EndDate = dateObj.End_Dt;
          } else {
            params.StartDate = header.customStartDate;
            params.EndDate = header.customEndDate;
          }
          dispatch(fetchSummary(params));
          dispatch(fetchSummaryTotal(params));
          dispatch(fetchCalenderDetails(params));
        }
      } else {
        let params = {
          StartDate: header.customStartDate,
          EndDate: header.customEndDate,
          STORE_CD: header.store,
        };
        setStartDate(header.customStartDate);
        setEndDate(header.customEndDate);
        dispatch(fetchEmployeeDetails(params));
      }
    } else {
      // Define params object with common properties
      const params = {
        ExcusalStatus: excusalStatus,
        Events: Util.getEvents(attendanceEventsSelected, header),
        Header: header,
        CORP_ACCESS: header.corporateEmployee == true ? "Y" : "N",
      };
      if (timePeriod !== "Custom") {
        const dateObj = timeframeData.find(
          (entry) => entry.Timeframe_Cd === "CUSTOM_DAY_" + timePeriod
        );
        setStartDate(dateObj.Start_Dt);
        setEndDate(dateObj.End_Dt);
        // Update params with specific dates
        params.StartDate = dateObj.Start_Dt;
        params.EndDate = dateObj.End_Dt;
      } else {
        setStartDate(header.customStartDate);
        setEndDate(header.customEndDate);
        // Update params with custom dates
        params.StartDate = header.customStartDate;
        params.EndDate = header.customEndDate;
      }
      // Dispatch actions
      dispatch(fetchSummary(params));
      dispatch(fetchSummaryTotal(params));
      dispatch(fetchCalenderDetails(params));
    }
    // Update popup and apply click states
    let obj = { ...header.reset };

    obj["store"] = header.store;
    obj["timePeriod"] = timePeriod;
    obj["excusalStatus"] = excusalStatus;
    obj["attendanceEvent"] = attendanceEventsSelected;

    dispatch(setFilterData({ state: "reset", value: obj }));

    setPopup(false);
    dispatch(setApplyClick(true));
  };

  const handleCancel = ()=>{
    let resetObj = {...header.reset};
    dispatch(setFilterData({ state: "store", value: resetObj.store }));
    dispatch(setFilterData({ state: "timePeriod", value: resetObj.timePeriod }));
    dispatch(setFilterData({ state: "excusalStatus", value: resetObj.excusalStatus }));
    dispatch(setFilterData({ state: "attendanceEvent", value: resetObj.attendanceEvent }));

    setTimePeriod(header.timePeriod);
    setExcusalStatus(header.excusalStatus);
    setAttendanceEventsSelected(header.attendanceEvent);

    setPopup(false);
  }

  useEffect(() => {
    if (Object.keys(header.selectedMember).length > 0) {
      let timeframeData = header.timeframeData;
      let selectedMember = header.selectedMember;
      const params = {
        "ExcusalStatus": excusalStatus,
        "Events": Util.getEvents(attendanceEventsSelected, header),
        "Header": header,
        "CORP_ACCESS": (header.corporateEmployee == true) ? 'Y' : 'N',
        "EMP_DS_ID": selectedMember.MASKED_EMP_DS_ID
      };
      if (timePeriod !== 'Custom') {
        const dateObj = timeframeData.find(entry => entry.Timeframe_Cd === 'CUSTOM_DAY_' + timePeriod && entry.Rptg_Stream_Name == header.store.Rptg_Stream_Name);
        params.StartDate = dateObj.Start_Dt;
        params.EndDate = dateObj.End_Dt;
      } else {
        params.StartDate = header.customStartDate;
        params.EndDate = header.customEndDate;
      }
      dispatch(fetchSummary(params));
      dispatch(fetchSummaryTotal(params));
      dispatch(fetchCalenderDetails(params));
    }
  }, [header.selectedMember])



  const getFilterPopUp = () => {
    let filterpopup = <div className="filter-info-popup" style={{ display: (popup == true) ? "block" : "none" }} >
      <div id="FilterContainer">
        <div id="inner-FilterContainer">
          <div className="filterbody">
            {header.corporateEmployee && (
              <div className='section store-section'>
                <ListView
                  items={storeList}
                  itemClick={onClickStore}
                  name={"Country"}
                  isSingleSelection={true}
                />
              </div>
            )}
            <div className="section time-period-section">
             <div class="section-label"><span class="section-category-label">Time Period</span></div>
              <BricksRadioButtonGroup className="radio-button-group" name="Time" onBricksRadioButtonGroupChange={(e) => setTimePeriod(e.target.value)}>
                {time_periods.map((time) => (
                  <BricksRadioButton
                    key={time}
                    label={((time !== 'Custom') ? ' Prior ' : '') + time + ((time !== 'Custom') ? ' Days' : '')}
                    value={time}
                    checked={(timePeriod == time) ? true : false}
                  />
                ))}
              </BricksRadioButtonGroup>
              <div className='date-picker' style={{ visibility: (timePeriod == 'Custom') ? 'visible' : 'hidden' }}>
                <BricksDatePicker
                  selectionMode="date-range"
                  label="Date Picker"
                  visualStyle="floating"
                  multiMonth
                  value={datePicker}
                  position='auto'
                  id="custom-date"
                  onBricksDatePickerChange={handleDateChange}
                />
              </div>
            </div>
            <div className="section excusal-status-section">
            <div class="section-label"><span class="section-category-label">Excusal Status</span></div>
              <BricksRadioButtonGroup className="radio-button-group" name="Excusal Status" onBricksRadioButtonGroupChange={(e) => setExcusalStatus(e.target.value)}>
                {absence_type.map((type) => (
                  <>
                  <BricksRadioButton
                    key={type}
                    label={type}
                    value={type}
                    checked={(excusalStatus == type) ? true : false}
                  />
                  {type=='All'?<hr></hr>:null}
                  </>
                ))}
              </BricksRadioButtonGroup>
            </div>
            <div className="section attendance-event-section">
              <div className="event-header-label">Attendance Event</div>
              <AttendanceEventComponent
                selectedEvents = {header.attendanceEvent}
                dispatchAttendanceEventsSelected={dispatchAttendanceEventsSelected}
              />
            </div>
          </div>
          <div className="filter-botton-container">
            <BricksButton visualStyle="alternative" accessibleTitle="Alternative" className="cancel" onClick={handleCancel}>Cancel</BricksButton>
            <BricksButton visualStyle="primary" accessibleTitle="primary" className="submit" onClick={handleSubmit}>Apply</BricksButton>
          </div>
        </div>
      </div>
    </div>
    return filterpopup;
  }

  const truncateString = (str, maxLength = 18) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  };

  const onPopupClick = () => {
    setPopup(!popup)
  }

  return (
    <div id="header">
      <div className='header-label' onClick={onPopupClick}>
        Personal Absence
      </div>
      <div className={`vertical-center ${popup ? `vertical-center-highlight` : ""}`} onClick={onPopupClick}>
        <div className='timeframe'>
        {(header.corporateEmployee) ? <div className='period'>
            <div className="timeperiod">Store</div>
            <div className="timeperiod-value">{Object.keys(header.store.length > 0) ? header.store.Store_Name :'- '}</div>
          </div> :''}
          {(header.corporateEmployee) ? <div className='separator-div'></div> : ''}
          <div className='period'>
            <div className="timeperiod">Time Period</div>
            <div className="timeperiod-value">{(header.timePeriod == 'Custom') ? '' : 'Prior'} {header.timePeriod} {(header.timePeriod == 'Custom') ? '' : 'days '}( {Util.formatDateforAudit(startDate)} to {Util.formatDateforAudit(endDate)} )</div>
          </div>
          <div className='separator-div'></div>
          <div className='period'>
            <div className="timeperiod">Excusal Status</div>
            <div className="absencetype-value">{excusalStatus}</div>
          </div>
          <div className='separator-div'></div>
          <div className='period'>
            <div className="timeperiod">Attendance Event</div>
            <div className="absencetype-value" title={Util.getEvents(attendanceEventsSelected, header).map(event => event).join(', ')}>{truncateString(Util.getEvents(attendanceEventsSelected, header).map(event => event).join(', '), 18)}</div>
          </div>
        </div>
        <div className={(popup) ? 'expand-collapse-collapse' : 'expand-collapse-expand'} onClick={onPopupClick}></div>
      </div>
      <div className='header-icon'>
        {/* <div className="language-div">
          <BricksSelector accessibleTitle="Select Box" position="auto" value={selectedLanguage} onBricksSelectorChange={handleLanguageChange}>
            <BricksMenuItem value="English">English</BricksMenuItem>
            <BricksMenuItem value="Thai">Thai</BricksMenuItem>
          </BricksSelector>
        </div> */}
        {/* <div className='notification-container-div'>
                <span className="in-app-notification-icon" title="Notification"></span>
            </div> */}
        <div className='user-guide-container'>
          <div className='user-guide-icon-div'>
            <span
              className="user-guide-info-icon"
              title="User Guide"
              onClick={onUserGuideIconClick}
              download
            ></span>
          </div>
        </div>
        <InAppSwitch
          appNotificationData={appNotificationData}
          showAppNotificationPopup={showAppNotificationPopup}
          setShowAppNotificationPopup={setShowAppNotificationPopup}
        />
      </div>
      {getFilterPopUp()}
    </div>
  )
}
export default Header;