import { createSlice } from '@reduxjs/toolkit';
import fetchApi from '../service/Service';

const initialState = {
    calenderDetails:[],
    error:null,
    loading:false,
}

export const fetchCalenderDetails = fetchApi('fetchCalenderDetails', 'POST');

export const calenderSlice = createSlice({
  name: 'calender',
  initialState,
  reducers: {
    setCalenderDetails:(state,action)=>{
      state[action.payload.state] = action.payload.value 
    },
  },
  
  extraReducers: (builder) => {
    /**
     * fetchData logic here.
     */
    builder.addCase(fetchCalenderDetails.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchCalenderDetails.fulfilled, (state, action) => {
      state.calenderDetails = action.payload.result
      state.loading = false
    })

    builder.addCase(fetchCalenderDetails.rejected, (state, action) => {
      const { error } = action
      state.error = error
      state.loading = false
    })
},
})

export const { setCalenderDetails} = calenderSlice.actions

export default calenderSlice.reducer