import React, { useEffect } from 'react';
import { BricksDialog } from '@nexus/bricks-react';
import './QuasiModalHeader.scss';

export default function QuasiModalHeader(props) {
  const [open, setOpen] = React.useState(props.showAppNotificationPopup);
  let appNotificationData = props.appNotificationData,
  showAppNotificationPopup = props.showAppNotificationPopup;

  useEffect(() => {
    if(!showAppNotificationPopup) {
        if(appNotificationData && appNotificationData.length > 0) {
            props.setShowAppNotificationPopup(true);
        }
    }
  }, [])  

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
    props.setShowAppNotificationPopup(false);
  };

  const notificationsHeaderStyle = {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px"
  };

  const getNotificationBody = () => {
    let divArr = [];
    if(appNotificationData && appNotificationData.length > 0){
      for(let index in appNotificationData) {
        let obj = appNotificationData[index],
            message = obj.message,
            div = <div className="message-div" key={index}>-&nbsp;&nbsp;{message}</div>;

        divArr.push(div);
      }
    }
    else{
      let div = <div className="message-div">No Notification To Display</div>;
      divArr.push(div);

    }
    return divArr
  }

  return (
    <div className="inapp-notification-popup">
      <BricksDialog behavior="quasimodal" className="inapp-notification-popup-modal" isOpen={open} onBricksDialogClose={closeDialog} closeButtonPlacement="left">
        <div slot="header">
          <div style={notificationsHeaderStyle} className="inapp-notification-header">Notifications</div>
        </div>
        <div slot="body" className="inapp-notification-container">
          {getNotificationBody()}
        </div>
      </BricksDialog>
    </div>
  );
}