import { createSlice } from '@reduxjs/toolkit';
import fetchApi from '../service/Service';

const initialState = {
  summary:[],
  summaryTotal:[],
  error:null,
  loading:false,
}

export const fetchSummary = fetchApi('fetchSummary', 'POST');
export const fetchSummaryTotal = fetchApi('fetchSummaryTotal', 'POST');

export const sumarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    /**
     * fetchData logic here.
     */
    builder.addCase(fetchSummary.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchSummary.fulfilled, (state, action) => {
      state.summary = action.payload.result
      state.loading = false
    })

    builder.addCase(fetchSummaryTotal.fulfilled, (state, action) => {
      state.summaryTotal = action.payload.result
      state.loading = false
    })

    builder.addCase(fetchSummary.rejected, (state, action) => {
      const { error } = action
      state.error = error
      state.loading = false
    })
},
})

//export const { setPopupState,setTabstate } = headerSlice.actions

export default sumarySlice.reducer