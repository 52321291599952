import React, { useState } from 'react';
import { BricksIcon } from '@nexus/bricks-react';
import './MenuDropdown.scss'; // Import CSS for styling

const MenuDropdown = ({ handleLanguageChange, selecteLanguage, onUserGuideIconClick }) => {
  // State to control the dropdown visibility and selected language
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(selecteLanguage);
  const languageOptions = {
    "English": "English (US)",
    "Thai": "Thai"
  };


  const svgData = `<svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      stroke="white"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-menu"
    >
    <line x1="2" y1="4" x2="14" y2="4"></line>
    <line x1="2" y1="8" x2="14" y2="8"></line>
    <line x1="2" y1="12" x2="14" y2="12"></line>
  </svg>`;

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle language selection
  const selectLanguage = (language) => {
    handleLanguageChange(language)
    setSelectedLanguage(language);
    setIsOpen(false); // Close dropdown after selection
  };

  const handleClickGuide = () =>{
    onUserGuideIconClick();
    setIsOpen(false); // Close dropdown after selection
  }

  return (
    <div className="language-dropdown">
      {/* Button to toggle the dropdown */}

      <BricksIcon slot="icon" size="large" svgData={svgData} onClick={toggleDropdown}></BricksIcon>


      {/* Overlay behind dropdown */}
      {isOpen && <div className="overlay" onClick={() => setIsOpen(false)}></div>}


      {/* Dropdown content */}
      {isOpen && (
        <div className="dropdown-content">
          {/* <div className='menu-header-label'>Language</div>
          <ul>
            {Object.entries(languageOptions).map(([key, value]) => (
              <li key={key} onClick={() => selectLanguage(key)}>
                <span className={`checkmark ${selectedLanguage === key ? 'checkmark--visible' : 'checkmark--transparent'}`}> ✓</span>
                {value}
              </li>
            ))}
          </ul> */}
          <div className="getting-started" onClick={handleClickGuide}>
            <span className='userGuide-icon'><label>Getting Started Guide</label></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDropdown;
