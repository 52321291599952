import React, { useState, useEffect } from 'react';
import './MobileHeader.scss';
import { BricksButton, BricksRadioButtonGroup, BricksRadioButton, BricksMenuItem, BricksDatePicker, BricksIcon, BricksMenuButton, BricksMenu, BricksLabel, BricksMenuDivider } from '@nexus/bricks-react';
import { useSelector, useDispatch } from 'react-redux';
import { setPopupState, setTimePeriodState, setApplyClick, setFilterData, fetchEmployeeDetails } from '../../slices/headerSlice';
import AttendanceEventComponent from '../common/AttendanceEventComponent';
import { fetchTimeframeData, fetchCorporateStoreList, fetchCorporateTimeframeData,fetchInAppNotificationData } from '../../slices/headerSlice';
import { fetchSummary, fetchSummaryTotal } from '../../slices/summarySlice';
import { fetchCalenderDetails } from '../../slices/calenderSlice';
import ListView from '../common/ListView';
import * as Util from "../../util/Util";
import * as XLSX from 'xlsx';
import InAppSwitch from '../common/inapp_notification/InAppSwitch';
import MenuDropdown from '../common/menu/MenuDropdown';

const MobileHeader = () => {
  const header = useSelector((state) => state.header),
  [timePeriod, setTimePeriod] = useState(header.timePeriod),
  [excusalStatus, setExcusalStatus] = useState(header.excusalStatus),
  [attendanceEventsSelected, setAttendanceEventsSelected] = useState(header.attendanceEvent),
    time_periods = ['30', '60', '90', '180', '365', 'Custom'],
    absence_type = ['All', 'Unexcused Only', 'Excused Only'],
    [datePicker, setDatePicker] = useState(''),
    [startDate, setStartDate] = useState(''),
    [endDate, setEndDate] = useState(''),
    [popup, setPopup] = useState(false),
    [startDateRangeFilter, setStartDateRangeFilter] = useState({}),
    [endDateRangeFilter, setEndDateRangeFilter] = useState({}),
    dispatch = useDispatch(),
    [selectedLanguage, setSelectLanguage] = useState(header.selectedLanguage),
    attendanceEventObject = {
      "Absence": ["Failure To Notify", "Callout", "No Punch"],
      "Time Keeping": ["Tardy", "Leave from Lunch", "Early Out"]
    },
    storeList = [],
    [appNotificationData, setAppNotificationData] = useState(''),
    [showAppNotificationPopup, setShowAppNotificationPopup] = useState(false),
    language = {
      "English": "English (US)",
      "Thai": "Thai"
    };

  const svgData = `<svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      stroke="white"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-menu"
    >
    <line x1="2" y1="4" x2="14" y2="4"></line>
    <line x1="2" y1="8" x2="14" y2="8"></line>
    <line x1="2" y1="12" x2="14" y2="12"></line>
  </svg>`;

  useEffect(() => {
    dispatch(setFilterData({ state: "isMobile", value: 'Y' }));
    if (!header.corporateEmployee) {
      dispatch(fetchTimeframeData());
    }
    dispatch(fetchInAppNotificationData());
  }, []);

  const handleLanguageChange = (e) => {
    setSelectLanguage(e);
  }

  const dispatchAttendanceEventsSelected = (values) => {
    setAttendanceEventsSelected(values);
  }

  useEffect(() => {
    dispatch(setPopupState(false))
    if (header.corporateEmployee) {
      dispatch(fetchCorporateTimeframeData());
      dispatch(fetchCorporateStoreList(header))
      setPopup(true)
      // dispatch(setPopupState(true))
    }
  }, [header.corporateEmployee]);

  useEffect(() => {
    if (header.inAppNotificationData){
      setAppNotificationData(header.inAppNotificationData)
    }
  }, [header.inAppNotificationData]);

  useEffect(() => {
    // Path to the local Excel file in the public folder
    const filePath = `${process.env.PUBLIC_URL}/multiLingual/Personal_Absence_` + selectedLanguage + `.xlsx`;

    fetch(filePath)
      .then((response) => response.arrayBuffer()) // Get the file as an ArrayBuffer
      .then((data) => {
        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const keyValuePairs = jsonData.slice(1).reduce((acc, [key, , source]) => {
          acc[key] = source;
          return acc;
        }, {});

        dispatch(setFilterData({ state: "language", value: keyValuePairs }));
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, [selectedLanguage]);

  useEffect(() => {
    let timeframeData = header.timeframeData;
    if (timeframeData.length > 0) {
      let dateObj = timeframeData.find(entry => entry.Timeframe_Cd == 'CUSTOM_DAY_' + timePeriod);

      setStartDate(dateObj.Start_Dt);
      setEndDate(dateObj.End_Dt);

      let params = {
        "StartDate": dateObj.Start_Dt,
        "EndDate": dateObj.End_Dt,
        "ExcusalStatus": excusalStatus,
        "Events": Util.getEvents(attendanceEventsSelected, header),
        "Header": header,
        "CORP_ACCESS": (header.corporateEmployee == true) ? 'N' : 'N',

      }
      //dispatch(fetchData(params));
      dispatch(fetchSummary(params));
      dispatch(fetchSummaryTotal(params));
      dispatch(fetchCalenderDetails(params));

      let startDateRange = Util.formatDateforFilter(dateObj.Start_Dt);
      let endDateRange = Util.formatDateforFilter(dateObj.End_Dt);
      setStartDateRangeFilter(startDateRange);
      setEndDateRangeFilter(endDateRange);
    }


  }, [header.timeframeData])

  const handleSubmit = () => {
    let timeframeData = header.timeframeData;
    if (header.corporateEmployee) {
      if (header.store == "") {
        alert('Please select a store');
        return false
      }
    }
    if (header.corporateEmployee) {
      dispatch(setTimePeriodState(timePeriod));
      if (timePeriod !== 'Custom') {
        let dateObj = timeframeData.find(entry => entry.Timeframe_Cd == 'CUSTOM_DAY_' +timePeriod && entry.Rptg_Stream_Name == header.store.Rptg_Stream_Name);
        setStartDate(dateObj.Start_Dt);
        setEndDate(dateObj.End_Dt);

        let startDateRange = Util.formatDateforFilter(dateObj.Start_Dt);
        let endDateRange = Util.formatDateforFilter(dateObj.End_Dt);
        setStartDateRangeFilter(startDateRange);
        setEndDateRangeFilter(endDateRange);
        let params = {
          "StartDate": dateObj.Start_Dt,
          "EndDate": dateObj.End_Dt,
          "STORE_CD": header.store,
        }

        dispatch(fetchEmployeeDetails(params));
      } else {
        let params = {
          "StartDate": header.customStartDate,
          "EndDate": header.customEndDate,
          "STORE_CD": header.store,
        }
        setStartDate(header.customStartDate);
        setEndDate(header.customEndDate);

        let startDateRange = Util.formatDateforFilter(header.customStartDate);
        let endDateRange = Util.formatDateforFilter(header.customEndDate);
        setStartDateRangeFilter(startDateRange);
        setEndDateRangeFilter(endDateRange);
        dispatch(fetchEmployeeDetails(params));
      }

    } else {
      dispatch(setTimePeriodState(timePeriod));
      // Define params object with common properties
      const params = {
        "ExcusalStatus": excusalStatus,
        "Events": Util.getEvents(attendanceEventsSelected, header),
        "Header": header,
        "CORP_ACCESS": (header.corporateEmployee == true) ? 'Y' : 'N',
      };

      if (timePeriod !== 'Custom') {
        const dateObj = timeframeData.find(entry => entry.Timeframe_Cd === 'CUSTOM_DAY_' + timePeriod);

        setStartDate(dateObj.Start_Dt);
        setEndDate(dateObj.End_Dt);

        let startDateRange = Util.formatDateforFilter(dateObj.Start_Dt);
        let endDateRange = Util.formatDateforFilter(dateObj.End_Dt);
        setStartDateRangeFilter(startDateRange);
        setEndDateRangeFilter(endDateRange);

        // Update params with specific dates
        params.StartDate = dateObj.Start_Dt;
        params.EndDate = dateObj.End_Dt;
      } else {
        setStartDate(header.customStartDate);
        setEndDate(header.customEndDate);

        let startDateRange = Util.formatDateforFilter(header.customStartDate);
        let endDateRange = Util.formatDateforFilter(header.customEndDate);
        setStartDateRangeFilter(startDateRange);
        setEndDateRangeFilter(endDateRange);

        // Update params with custom dates
        params.StartDate = header.customStartDate;
        params.EndDate = header.customEndDate;
      }
      // Dispatch actions
      dispatch(fetchSummary(params));
      dispatch(fetchSummaryTotal(params));
      dispatch(fetchCalenderDetails(params));
    }

    // Update popup and apply click states

    let obj = { ...header.reset };

    obj["store"] = header.store;
    obj["timePeriod"] = timePeriod;
    obj["excusalStatus"] = excusalStatus;
    obj["attendanceEvent"] = attendanceEventsSelected;

    dispatch(setFilterData({ state: "reset", value: obj }));

    dispatch(setApplyClick(true));
    dispatch(setPopupState(false));
    setPopup(false);
  };

  const handleCancel = ()=>{
    let resetObj = {...header.reset};
    dispatch(setFilterData({ state: "store", value: resetObj.store }));
    dispatch(setFilterData({ state: "timePeriod", value: resetObj.timePeriod }));
    dispatch(setFilterData({ state: "excusalStatus", value: resetObj.excusalStatus }));
    dispatch(setFilterData({ state: "attendanceEvent", value: resetObj.attendanceEvent }));

    setTimePeriod(header.timePeriod);
    setExcusalStatus(header.excusalStatus);
    setAttendanceEventsSelected(header.attendanceEvent);

    setPopup(false);
  }

  const cancelClick = () => {
    setPopup(false);
  };

  const truncateString = (str, maxLength = 18) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  };

  const onClickStore = (store) => {
    dispatch(setFilterData({ state: "store", value: store }));
  }

  const formatDate = (date) => {
    const [month, day, year] = date.split("/");
    const inputDate = new Date(year, month - 1, day); // Month is 0-based in Date object
    const formattedDate = `${inputDate.getFullYear()}-${month}-${day}`;
    return formattedDate
  }

  useEffect(() => {
    if (Object.keys(header.selectedMember).length > 0) {
      let timeframeData = header.timeframeData;
      let selectedMember = header.selectedMember;
      const params = {
        "ExcusalStatus": excusalStatus,
        "Events": Util.getEvents(attendanceEventsSelected, header),
        "Header": header,
        "CORP_ACCESS": (header.corporateEmployee == true) ? 'Y' : 'N',
        "EMP_DS_ID": selectedMember.MASKED_EMP_DS_ID
      };
      if (timePeriod !== 'Custom') {
        const dateObj = timeframeData.find(entry => entry.Timeframe_Cd === 'CUSTOM_DAY_' + timePeriod && entry.Rptg_Stream_Name == header.store.Rptg_Stream_Name);
        params.StartDate = dateObj.Start_Dt;
        params.EndDate = dateObj.End_Dt;
      } else {
        params.StartDate = header.customStartDate;
        params.EndDate = header.customEndDate;
      }
      dispatch(fetchSummary(params));
      dispatch(fetchSummaryTotal(params));
      dispatch(fetchCalenderDetails(params));
    }
  }, [header.selectedMember])

  const handleDateChange = (e) => {
    if (e.detail.value) {
      dispatch(setFilterData({ state: "customStartDate", value: formatDate(e.detail.value[0]) }));
      dispatch(setFilterData({ state: "customEndDate", value: formatDate(e.detail.value[1]) }));

      const dateRange = document.getElementById('custom-date');
      dateRange.value = [e.detail.value[0], e.detail.value[1]];
      setDatePicker([e.detail.value[0], e.detail.value[1]])
    }
  };

  const handleCorporateDateChange = (e) => {
    if (e.detail.value) {
      dispatch(setFilterData({ state: "customStartDate", value: formatDate(e.detail.value[0]) }));
      dispatch(setFilterData({ state: "customEndDate", value: formatDate(e.detail.value[1]) }));

      const dateRange = document.getElementById('corporate-custom-date');
      dateRange.value = [e.detail.value[0], e.detail.value[1]];
      setDatePicker([e.detail.value[0], e.detail.value[1]])
    }
  };

  const getFilterPopUp = () => {
    let filterpopup = <div className="filter-info-popup" style={{ display: (popup == true) ? "block" : "none" }} >
      <div id="FilterContainer">
        <div id="inner-FilterContainer">
          {header.corporateEmployee && (
            <div className='section'>
              <ListView
                items={storeList}
                itemClick={onClickStore}
                name={"Country"}
                isSingleSelection={true}
              />
            </div>
          )}
          <div className="filterbody">

            <div className="section">
            <div class="section-label"><span class="section-category-label">Time Period</span></div>
              <BricksRadioButtonGroup className="radio-button-group"  name="Time" onBricksRadioButtonGroupChange={(e) => setTimePeriod(e.target.value)}>
                {time_periods.map((time) => (
                  <BricksRadioButton
                    key={time}
                    label={((time !== 'Custom') ? ' Prior ' : '') + time + ((time !== 'Custom') ? ' Days' : '')}
                    value={time}
                    checked={(timePeriod == time) ? true : false}

                  />
                ))}

              </BricksRadioButtonGroup>

              <div className='date-picker' style={{ visibility: (timePeriod == 'Custom') ? 'visible' : 'hidden' }}>
                <BricksDatePicker
                  selectionMode="date-range"
                  label="Date Picker"
                  visualStyle="floating"
                  multiMonth
                  value={datePicker}
                  position='auto'
                  id="custom-date"
                  onBricksDatePickerChange={handleDateChange}

                />
              </div>
            </div>
            <div className="section Absence">
            <div class="section-label"><span class="section-category-label">Excusal Status</span></div>
              <BricksRadioButtonGroup className="radio-button-group"  name="Excusal Status" onBricksRadioButtonGroupChange={(e) => setExcusalStatus(e.target.value)}>
                {absence_type.map((type) => (
                  <>
                    <BricksRadioButton
                      key={type}
                      label={type}
                      value={type}
                      checked={(excusalStatus == type) ? true : false}
                    />
                    {type == 'All' ? <hr className="radio-button-hr"></hr> : null}
                  </>
                ))}
              </BricksRadioButtonGroup>
            </div>
          </div>
          <div className="section attendance-event-section">
            <div className="attendance-event-header-label">Attendance Event</div>
            <div className='attendance-div'>
              <AttendanceEventComponent
                dispatchAttendanceEventsSelected={dispatchAttendanceEventsSelected}
              />
            </div>
          </div>
          <div className="filter-botton-container">
            <BricksButton visualStyle="alternative" accessibleTitle="Alternative" className="cancel" onClick={cancelClick}>Cancel</BricksButton>
            <BricksButton visualStyle="primary" accessibleTitle="primary" className="submit" onClick={handleSubmit}>Apply</BricksButton>
          </div>
        </div>
      </div>
    </div>

    return filterpopup;
  }

  const getCorporateFilterPopUp = () => {
    let filterpopup = <div className="filter-corporate-popup" style={{ display: (popup == true) ? "block" : "none" }} >
      <div id="FilterContainer">
        <div id="inner-FilterContainer">
          {header.corporateEmployee && (
            <div className='section'>
              <ListView
                items={storeList}
                itemClick={onClickStore}
                name={"Country"}
                isSingleSelection={true}
              />
            </div>
          )}
          <div className="filterbody">

            <div className="section1">
            <div class="section-label"><span class="section-category-label">Time Period</span></div>
              <BricksRadioButtonGroup className="radio-button-group"  name="Time" onBricksRadioButtonGroupChange={(e) => setTimePeriod(e.target.value)}>
                {time_periods.map((time) => (
                  <BricksRadioButton
                    key={time}
                    label={((time !== 'Custom') ? ' Prior ' : '') + time + ((time !== 'Custom') ? ' Days' : '')}
                    value={time}
                    checked={(timePeriod == time) ? true : false}

                  />
                ))}

              </BricksRadioButtonGroup>

              <div className={`date-picker-corporate ${timePeriod == 'Custom' ? 'date-picker-corporate-custom-visible' : 'date-picker-corporate-custom-hidden'}`}>
                <BricksDatePicker
                  selectionMode="date-range"
                  label="Date Picker"
                  visualStyle="floating"
                  multiMonth
                  value={datePicker}
                  position='auto'
                  id="corporate-custom-date"
                  onBricksDatePickerChange={handleCorporateDateChange}

                />
              </div>
            </div>
            <div className="section1 Absence">
            <div class="section-label"><span class="section-category-label">Excusal Status</span></div>
              <BricksRadioButtonGroup className="radio-button-group"  name="Excusal Status" onBricksRadioButtonGroupChange={(e) => setExcusalStatus(e.target.value)}>
                {absence_type.map((type) => (
                  <BricksRadioButton
                    key={type}
                    label={type}
                    value={type}
                    checked={(excusalStatus == type) ? true : false}
                  />
                ))}
              </BricksRadioButtonGroup>
            </div>
          </div>
          <div className="section">
            <div className="attendance-event-header-label">Attendance Event</div>
            <div className='attendance-div'>
              <AttendanceEventComponent
                dispatchAttendanceEventsSelected={dispatchAttendanceEventsSelected}
              />
            </div>
          </div>
          <div className="filter-botton-container">
            <BricksButton visualStyle="alternative" accessibleTitle="Alternative" className="cancel" onClick={handleCancel}>Cancel</BricksButton>
            <BricksButton visualStyle="primary" accessibleTitle="primary" className="submit" onClick={handleSubmit}>Apply</BricksButton>
          </div>
        </div>
      </div>
    </div>

    return filterpopup;
  }

  const onPopupClick = () => {
    setPopup(!popup);
  }

  const onUserGuideIconClick = () => {
    const pdfFilePath = `${process.env.PUBLIC_URL}/multiLingualGuideFiles/Personal_Absence_` + selectedLanguage + `.pdf`;
    // using Java Script method to get PDF file
    fetch(pdfFilePath).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Personal_Absence_" + selectedLanguage + ".pdf";
        alink.click();
      });
    });
  };

  // const styles = {
  //   pageState: {
  //     width: '16px',
  //     height: '16px',
  //   },
  //   selected: {
  //     backgroundColor: '#fff',
  //     backgroundImage: "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22%3E%3Cpath fill=%22%23000000%22 d=%22M20.285 6.708c-.391-.39-1.023-.39-1.414 0L9 16.579l-3.878-3.878c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414l4.585 4.585c.39.39 1.023.39 1.414 0L20.285 8.122c.391-.391.391-1.024 0-1.414z%22/%3E%3C/svg%3E')"
  //   },
  //   deprecated: {
  //     backgroundColor: '#d0ee9e'
  //   },
  //   finalised: {
  //     backgroundColor: '#c4f7f2'
  //   },
  //   needsReview: {
  //     backgroundColor: '#abeea0'
  //   },
  //   wip: {
  //     backgroundColor: '#f9eafd'
  //   },
  //   menuLabel: {
  //     padding: '8px 8px'
  //   },
  //   help: {
  //     backgroundColor: '#fff',
  //     backgroundImage: "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22currentColor%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 class=%22feather feather-book-open%22%3E%3Cpath d=%22M2 3h20v18H2z%22/%3E%3Cpath d=%22M2 3l10 6 10-6%22/%3E%3C/svg%3E')"
  //   }
  // };
  return (
    <div id="mobile-header" className={header.corporateEmployee ? 'corporate' : 'non-corporate'} >
      <div className="header-section">
        <div className="left-section">
          <MenuDropdown handleLanguageChange={handleLanguageChange} selecteLanguage={selectedLanguage} onUserGuideIconClick={onUserGuideIconClick}/>
          {/* <BricksMenuButton className="docs__icon-only-menu-button" aria-label="Choose an Action" >
            <BricksButton slot="trigger" variation="icon-only" size="small" shape="round-rect">
              <BricksIcon slot="icon" size="large" svgData={svgData}></BricksIcon>
            </BricksButton>

            <BricksMenu>
              <BricksLabel style={{ ...styles.menuLabel }}>Language</BricksLabel>

              {Object.keys(language).map((key) => (
                <BricksMenuItem
                  key={key}
                  value={key}
                  onClick={() => handleLanguageChange(key)}
                >
                  <div
                    style={selectedLanguage === key
                      ? { ...styles.pageState, ...styles.selected }
                      : { ...styles.pageState }}
                    slot="prefix"
                  ></div>{language[key]}
                </BricksMenuItem>
              ))}

              <BricksMenuItem style={{ ...styles.menuLabel }}  onClick={onUserGuideIconClick}>
                <div style={{ ...styles.pageState, ...styles.help }} slot="prefix"></div>
                Getting Started Guide
              </BricksMenuItem>
            </BricksMenu>
          </BricksMenuButton> */}
          {/* <BricksIcon color="primary" size="extra-large" ></BricksIcon> */}
        </div>
        <div className="header-title">Personal Absence</div>
        <div className="right-section">
          <InAppSwitch
            appNotificationData={appNotificationData}
            showAppNotificationPopup={showAppNotificationPopup}
            setShowAppNotificationPopup={setShowAppNotificationPopup}
          />

        </div>
      </div>
      {
        header.corporateEmployee ? (
          <div className='corporate-filter' onClick={onPopupClick}>
            <div className="item">
              <div className="filter-title">Store</div>
              <div className="filter-value">{(header.store.Store_Name != undefined) ? header.store.Store_Name : '-'}</div>
            </div>
            <div className="item time-period">
              <div className="filter-title">Time Period</div>
              <div className="value">
                <div className="filter-value">{(header.timePeriod == 'Custom') ? '' : 'Prior'} {header.timePeriod} {(header.timePeriod == 'Custom') ? '' : 'days '}</div>
                <div className="date-range">
                  <div>
                    <div className="month-day">{startDateRangeFilter.Day + ' ' + startDateRangeFilter.Month}</div>
                    <div className="year">{startDateRangeFilter.Year}</div>
                  </div>
                  <div className="hypen">-</div>
                  <div>
                    <div className="month-day">{endDateRangeFilter.Day + ' ' + endDateRangeFilter.Month}</div>
                    <div className="year">{endDateRangeFilter.Year}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="filter-title">Excusal Status</div>
              <div className="filter-value">{excusalStatus}</div>
            </div>
            <div className="item">
              <div className="filter-title">Attendance Event</div>
              <div className="filter-value" title={Util.getEvents(attendanceEventsSelected, header).map(event => event).join(', ')}>
                {truncateString(Util.getEvents(attendanceEventsSelected, header).map(event => event).join(', '), 18)}
              </div>
            </div>
          </div>
        ) : (
          <div className="filter-section" onClick={onPopupClick}>
            <div className="left-section">
              <div className="filter-title">Time Period</div>
              <div className="timeperiod-value">
                {(header.timePeriod === 'Custom') ? '' : 'Prior'} {header.timePeriod} {(header.timePeriod === 'Custom') ? '' : 'days '}
              </div>
              <div className="date-range">
                <div>
                  <div className="month-day">{startDateRangeFilter.Day + ' ' + startDateRangeFilter.Month}</div>
                  <div className="year">{startDateRangeFilter.Year}</div>
                </div>
                <div className="hypen">-</div>
                <div>
                  <div className="month-day">{endDateRangeFilter.Day + ' ' + endDateRangeFilter.Month}</div>
                  <div className="year">{endDateRangeFilter.Year}</div>
                </div>
              </div>
            </div>
            <div className="middle-section">
              <div className="filter-title">Excusal Status</div>
              <div className="excusal-value">{excusalStatus}</div>
            </div>
            <div className="right-section">
              <div className="filter-title">Attendance Event</div>
              <div className="absencetype-value" title={Util.getEvents(attendanceEventsSelected, header).map(event => event).join(', ')}>
                {truncateString(Util.getEvents(attendanceEventsSelected, header).map(event => event).join(', '), 18)}
              </div>
            </div>
          </div>
        )
      }
      <div className="expand-collapse-section">
        <div className={(popup) ? 'expand-collapse-collapse' : 'expand-collapse-expand'} onClick={onPopupClick}></div>
      </div>

      {header.corporateEmployee ? getCorporateFilterPopUp() : getFilterPopUp()}
    </div>
  )
}

export default MobileHeader;