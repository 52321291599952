import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BricksTooltip } from '@nexus/bricks-react';
import * as Util from '../../util/Util';
import NoDataDisplayContainer from '../common/nodata_display_container/NoDataDisplayContainer';
import {setCalenderDetails} from '../../slices/calenderSlice';
import './Calender.scss';

const Calender = () => {
    const calenderData = useSelector((state) => state.calender);
    const header = useSelector((state) => state.header);
    const dispatch = useDispatch();
    const scheduleWeekMapping = {
        'SAT': ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        'MON': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        'SUN': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    },
    dayCapitalMapping = {
        'Saturday': 'SATURDAY', 'Sunday': 'SUNDAY', 'Monday': 'MONDAY', 'Tuesday': 'TUESDAY', 'Wednesday': 'WEDNESDAY', 'Thursday': 'THURSDAY', 'Friday': 'FRIDAY'
    },
    eventShortNameMapping = {
        'Failure to Notify': 'FTN',
        'Callout': 'CO',
        'No Punch': 'NP',
        'Tardy': 'TD',
        'Late From Lunch': 'LL',
        'Early Out': 'EO'
    },
    legendShapesTextMapping = {
        // 'Out of Scope': {
        //     text: 'Out of Scope',
        //     shapeCls: 'legend-icon-out-of-scope',
        //     textCls: 'out-of-scope'
        // },
        'Excused': {
            text: 'Excused',
            shapeCls: 'legend-icon-excused',
            textCls: 'excused'
        },
        'Unexcused': {
            text: 'Unexcused',
            shapeCls: 'legend-icon-unexcused',
            textCls: 'unexcused'
        }, 
        'No Event': {
            text: 'No Event',
            shapeCls: 'legend-icon-no-event',
            textCls: 'no-event'
        }
    };

    let [calendarGroupedData, setCalendarGroupedData] = useState(""),
        [schedulingDayArr, setSchedulingDayArr] = useState(""),
        [occurranceIndicator,setOccurrranceIndicator] = useState("Y"),
        [pointBalance, setPointBalance] = useState([]);

   
    const getStartDate = (dataObj) => {
        let firstObjVal = Object.values(dataObj)[0],
            obj = firstObjVal ?. length > 0 ? firstObjVal[0]: "",
            startDate = obj ? obj.Week_Start_Dt : "",
            formattedDate = startDate ? Util.getFormattedDate(startDate) : ""

        return formattedDate;
    }
    const groupByTwoProperties = (arr, key1, key2) => {
        return arr.reduce((result, obj) => {
          // Get the values of the two keys
          const key1Value = obj[key1],
            key2Value = obj[key2];
          
          // Initialize the first level if it doesn't exist
          if (!result[key1Value]) {
            result[key1Value] = {};
          }
          
          // Initialize the second level if it doesn't exist
          if (!result[key1Value][key2Value]) {
            result[key1Value][key2Value] = [];
          }
          
          // Add the object to the corresponding nested array
          result[key1Value][key2Value].push(obj);
          
          return result;
        }, {});
    }   

    useEffect(() => {
        if (Object.keys(header.selectedMember).length == 0) {
            dispatch(setCalenderDetails({ state: "calenderDetails", value: [] }));
        }
      }, [header.selectedMember])

    //const calendarGroupedData = groupByTwoProperties(calenderDetails, 'Child_Cd', 'Day_Name');
    const getLegendShapes = () => {
        let legendItemsDiv = [];

        for(let obj of Object.values(legendShapesTextMapping)) {
            let div =  <div class="calender-legend-item">
                <div class={obj.shapeCls}></div>
                <div class={`legend-shape-text ${obj.textCls}`}>{obj.text}</div>
            </div>

            legendItemsDiv.push(div);
        }

        return (<div className="legend-shapes">{legendItemsDiv}</div>)
    }
    const getLegendShortcuts = () => {
        let legendShortcutArr = [];

        for(let item in eventShortNameMapping) {
            let div = <div class="calender-legend-item">
                <div class="legend-shortcut-text">{eventShortNameMapping[item]}</div>
                <div class="legend-shortcut-text">{item}</div>
            </div>

            legendShortcutArr.push(div);
        }

        return (<div className="legend-shortcuts">{legendShortcutArr}</div>)
    }
    const getNoEventTooltipContent = (eventName, eventStatus, startDate, clockedTime) => {

        let formattedDate = Util.getFormattedDate(startDate),
            formattedClocktime = Util.getFormattedTime(clockedTime,':');

        let content = <div class="event-tooltip-div">
            <div class="event-tooltip-header">{eventName}</div>
            <div class="event-tooltip-date">
                <span class="event-tooltip-calendar-icon"></span>
                <span>{formattedDate}</span>
            </div>
            <div class="event-tooltip-clocktime">
                <span class="event-tooltip-clock-icon"></span>
                <span>{formattedClocktime}</span>
            </div>
        </div>
           
        return content;
    }
    const getUnexcusedDetails = (employeeDataObj) => {
        let unexcusedString = '';

        //if (employeeDataObj.Excusal_Status_Cd == 'Unexcused') {
            if (employeeDataObj.Unapproved_Abs_Hrs > 0) {
                unexcusedString = Util.getFormattedTime(employeeDataObj.Unapproved_Abs_Hrs,' ') + ' - ' + 'Unexcused' + ' - Leave'
            } else if (employeeDataObj.Unexcused_Benefit_Time_Hrs > 0) {
                unexcusedString = Util.getFormattedTime(employeeDataObj.Unexcused_Benefit_Time_Hrs,' ') + ' - ' + 'Unexcused' + ' - Time Away'
            }

            return unexcusedString
        //}
    }
    const getexcusedDetails = (employeeDataObj) => {
        let excusedString = '';

        //if (employeeDataObj.Excusal_Status_Cd == 'Excused') {
            if (employeeDataObj.Leave_Of_Absence_Hrs > 0) {
                excusedString = Util.getFormattedTime(employeeDataObj.Leave_Of_Absence_Hrs,' ') + ' - ' + 'Excused' + ' - Leave'
            } else if (employeeDataObj.Excused_Benefit_Time_Hrs > 0) {
                excusedString = Util.getFormattedTime(employeeDataObj.Excused_Benefit_Time_Hrs,' ') + ' - ' + 'Excused' + ' - Time Away'
            } else if (employeeDataObj.Excused_Leader_Hrs > 0) {
                excusedString = Util.getFormattedTime(employeeDataObj.Excused_Leader_Hrs,' ') + ' - ' + 'Excused' + ' - By Leader'
            }

            return excusedString
        //}
    }
    const getExcusedUnexcusedDetails = (employeeDataObj) => {
        let divArr = [],
            excusalDetails = getexcusedDetails(employeeDataObj),
            unExcusalDetails = getUnexcusedDetails(employeeDataObj);

        // if(employeeDataObj.Excusal_Status_Cd == 'Unexcused') {
        //     unExcusalDetails = getUnexcusedDetails(employeeDataObj);
        // } else if(employeeDataObj.Excusal_Status_Cd == 'Excused') {
        //     excusalDetails = getexcusedDetails(employeeDataObj);
        // }

        if(unExcusalDetails || excusalDetails) {
            divArr.push(<div className='separator'/>);

            if(unExcusalDetails) {
                divArr.push(<div class="event-tooltip-clocktime">
                    <span className="event-padding">{unExcusalDetails}</span>
                </div>)
            }
            if(excusalDetails) {
                divArr.push(<div class="event-tooltip-clocktime">
                    <span className="event-padding">{excusalDetails}</span>
                </div>)
            }
        }

        return <>{divArr}</>
    }
    const getEventTooltipContent = (employeeDataObj) => {
        let clockedTime = (employeeDataObj.Event_Name === 'No Event') ? employeeDataObj.Clocked_Time_Hrs : employeeDataObj.Tot_Impacted_Hours_Val,
            formattedDate = Util.getFormattedDate(employeeDataObj.Rptg_Dt),
            shiftTime = Util.getFormattedTime(employeeDataObj.Tot_Planned_Hours_Val, ':'),
            shiftStartTime = Util.convertToAmPm(employeeDataObj.Shift_Start_Tm_Aggr),
            shiftEndTime = Util.convertToAmPm(employeeDataObj.Shift_End_Tm_Aggr),
            formattedClocktime = Util.getFormattedTime(clockedTime, ':');

        let content = <div class="event-tooltip-div">
            <div class="event-tooltip-header">{employeeDataObj.Event_Name} - {employeeDataObj.Excusal_Status_Cd}</div>
            <div class="event-tooltip-date">
                <span class="event-tooltip-calendar-icon"></span>
                <span>{formattedDate}</span>
            </div>
            <div class="event-tooltip-clocktime">
                <span class="event-tooltip-shift-icon"></span>
                <span>{shiftStartTime+' - '+shiftEndTime+' ('+shiftTime+')'}</span>
            </div>
            <div class="event-tooltip-clocktime">
                <span class="event-tooltip-impact-icon"></span>
                <span>{formattedClocktime}{' Impact'}</span>
            </div>
            {getExcusedUnexcusedDetails(employeeDataObj)}
        </div>

        return content;
    }

    const getColumnHeader = () => {
        let calenderDetails = calenderData.calenderDetails ? calenderData.calenderDetails : [],
            schedulingWeek,
            headerArr = [];

    if(calenderDetails ?. length > 0) {
        let dataObj = calenderDetails[0];
        schedulingWeek = dataObj.Week_Start_Day ? dataObj.Week_Start_Day : 'SAT';
    }

    if(schedulingWeek) {
        for(let week of scheduleWeekMapping[schedulingWeek]) {
            headerArr.push(<th>{week}</th>)
        }
    }

    return (<tr>
        <th></th>
        {headerArr}
    </tr>)
    }
    const getSchedulingDayArr = () => {
        let calenderDetails = calenderData.calenderDetails ? calenderData.calenderDetails : [],
            schedulingWeek;

        if(calenderDetails ?. length > 0) {
            let dataObj = calenderDetails[0];
            schedulingWeek = dataObj.Week_Start_Day ? dataObj.Week_Start_Day : 'SAT';
        }

        return scheduleWeekMapping[schedulingWeek];
    }
    const getTableBody = () => {
        let tableBody = [];

        for(let week in calendarGroupedData) {
            let calendarGroupedDataObj = calendarGroupedData[week],
                firstColumn = <td className='qtr-week-td'>
                    <div className='week-div'>{week}</div>
                    <div className='date-div'>{getStartDate(calendarGroupedDataObj)}</div>
                </td>,
                calendarItemsTd = [];
            let schedulingDayArr = getSchedulingDayArr();


            for(let schedulingDay of schedulingDayArr) {
                let schedulingDayCapital = dayCapitalMapping[schedulingDay],
                    employeeDataArr = calendarGroupedDataObj[schedulingDayCapital] ? calendarGroupedDataObj[schedulingDayCapital] : [],
                    employeeDataDiv = [];

                for(let employeeDataObj of employeeDataArr) {
                    let eventName = employeeDataObj.Event_Name,
                        eventStatus = employeeDataObj.Excusal_Status_Cd,
                        startDate = employeeDataObj.Rptg_Dt,
                        shift = employeeDataObj.Tot_Planned_Hours_Val,
                        clockedTime = (eventName === 'No Event') ? employeeDataObj.Clocked_Time_Hrs : employeeDataObj.Tot_Impacted_Hours_Val,
                        tooltipContent = (eventName === 'No Event') ? getNoEventTooltipContent(eventName, eventStatus, startDate, clockedTime) : getEventTooltipContent(employeeDataObj),
                        excludeInd = employeeDataObj.Exclude_Evnet_Ind,
                        tooltipInd = true;

                        if(eventName == 'No Event' && excludeInd == 'Y') {
                            tooltipInd = false;
                        }

                        if (tooltipInd) {
                            employeeDataDiv.push(
                                <div class="calendar-item">
                                    {tooltipInd 
                                        ?
                                            <BricksTooltip className="generic-tooltip" placement="top">
                                                <div slot="trigger" className={`info-tooltip calendar-item-shape ${legendShapesTextMapping[eventStatus].shapeCls}`}></div>
                                                <div className="tooltip-contents" >{tooltipContent}</div>
                                            </BricksTooltip>
                                        :
                                        <div className={`calendar-item-shape ${legendShapesTextMapping[eventStatus].shapeCls}`}></div>
                                    }
                                    <div className={`calendar-item-text ${legendShapesTextMapping[eventStatus].textCls}`}>{eventShortNameMapping[eventName]}</div>
                                </div>
                            )
                        }
                }

                calendarItemsTd.push(
                    <td className='event-td'>
                        <div class="calendar-items">{employeeDataDiv}</div>
                    </td>
                )
            }

            tableBody.push(
                <tr>
                    {firstColumn}
                    {calendarItemsTd}
                </tr>
            )
        }

        return tableBody;
    }

    useEffect(() => {
        let groupedData = groupByTwoProperties(calenderData.calenderDetails, 'Child_Cd', 'Day_Name'),
            dayArr = getSchedulingDayArr();

        setSchedulingDayArr(dayArr);
        setCalendarGroupedData(groupedData);
    }, [calenderData.calenderDetails])

    return (
        <div className="calender-container">
            <div className="calender-header">Calendar View</div>
            {
                calenderData.calenderDetails ?. length > 0 
                ?
                (<><div class="calender-legend">
                    {getLegendShapes()}
                    {getLegendShortcuts()}
                </div>
                <div className='calender-table'>
                    <table class="attendance-table">
                        <thead>{getColumnHeader()}</thead>
                        <tbody>
                            {getTableBody()}
                        </tbody>
                    </table>
                </div></>)
                :
                <NoDataDisplayContainer msg={'No Data to Display'}/>
            }
            
        </div>
    )
}

export default Calender;